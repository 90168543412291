import { Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';

/**
 * Provides a radio button group for selecting the type of annual report,
 * @param {object} props - The component props.
 * @param {object} props.companyData - The company data object containing details like the country.
 * @param {string} props.companyData.country - The country code of the company (e.g., 'AT', 'DE').
 * @returns {JSX.Element} The rendered AnnualReportInput component.
 * @component
 */
const AnnualReportInput = ({ companyData }) => {
  const { t } = useTranslation();
  const EURLabel = companyData?.country === 'AT' ? 'EAR' : 'EUR';

  return (
    <div>
      <h4>
        {t(
          'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.annualReport.label',
        )}
      </h4>
      <Radio.Group name="annualReport">
        <Radio.Button value="both">
          {t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.annualReport.options.both',
          )}
        </Radio.Button>
        <Radio.Button value="EUR">
          {t(
            `admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.annualReport.options.${EURLabel}`,
          )}
        </Radio.Button>
        <Radio.Button value="BIL">
          {t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.annualReport.options.BIL',
          )}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default AnnualReportInput;
