import { gql } from '@apollo/client';
import userCompanyFragment from './userCompanyFragment';
import userContactFragment from './userContactFragment';
import userStatusFragment from './userStatusFragment';

const userShoppingCartViewTotalValueFragment = gql`
  fragment userShoppingCartViewTotalValueFragment on TotalValue {
    _id
    _apolloCacheKey
    value
    discountedValue
    discountedVAT
    highlightBindingnessValues
  }
`;
const userShoppingCartCategoryTotalViewFragment = gql`
  fragment userShoppingCartCategoryTotalViewFragment on ShoppingCartCategoryTotal {
    oneOff {
      ...userShoppingCartViewTotalValueFragment
    }
    monthly {
      ...userShoppingCartViewTotalValueFragment
    }
    yearly {
      ...userShoppingCartViewTotalValueFragment
    }
  }
  ${userShoppingCartViewTotalValueFragment}
`;

const userShoppingCartViewFragment = gql`
  fragment userShoppingCartViewFragment on ShoppingCart {
    _id
    categories {
      _apolloCacheKey
      _id
      items {
        _id
        calculationMode
        highlightBindingness
        customPrice
        value
        discountedValue
        officialReasonText
        internalNoteToTeam
        showCalculatedPrice
        wasBookmarkedItem
      }
      bookmarkedItems {
        _id
        customPrice
        calculationMode
        highlightBindingness
        value
        discountedValue
        officialReasonText
        internalNoteToTeam
        showCalculatedPrice
      }
      discountId
      inputFields {
        _apolloCacheKey
        _id
        value
        defaultValue
      }
      startOfService
      showPeriod
      startPeriod
      endPeriod
      total {
        ...userShoppingCartCategoryTotalViewFragment
      }
    }
    createdBy {
      _id
      fullName
      profile {
        firstName
        lastName
      }
    }
    documentTemplates {
      _id
    }
    createdAt
    digitalSignatureState
    feeType
    initializationConfigDate
    meetingAt
    migrated
    migrationDate
    monthlyPaymentDecision
    name
    showVat
    showDigits
    showPrices
    isStarting
    monthly {
      ...userShoppingCartViewTotalValueFragment
    }
    monthlyAdvance {
      ...userShoppingCartViewTotalValueFragment
    }
    oneOff {
      ...userShoppingCartViewTotalValueFragment
    }
    yearly {
      ...userShoppingCartViewTotalValueFragment
    }
    startOfContract
    company {
      ...userCompanyFragment
    }
    contacts {
      ...userContactFragment
    }
    signatureData {
      companySignees {
        email
        firstName
        lastName
        id
        lastRemindedDate
      }
      tenantSignees {
        email
        firstName
        lastName
        id
        lastRemindedDate
      }
      signedCompanySignees {
        signeeEmail
        signeeName
        relatedContactObject
        signDate
        signIP
      }
      signedTenantSignees {
        signeeEmail
        signeeName
        relatedTenantObject
        signDate
        signIP
      }
      digitalSignatureConfigDate
      sepaEnabled
      sepaMode
      sepaType
      digitalSignatureActions {
        actionType
        additionalInfos
      }
    }
    status {
      ...userStatusFragment
    }
    vatType
    vat {
      _id
      value
    }
  }
  ${userShoppingCartViewTotalValueFragment}
  ${userShoppingCartCategoryTotalViewFragment}
  ${userStatusFragment}
  ${userContactFragment}
  ${userCompanyFragment}
`;

export default userShoppingCartViewFragment;
