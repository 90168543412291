import { useTranslation } from 'react-i18next';
import RadioButtonsWithLabel from 'components/common/FormComponents/Formik/RadioButtonsWithLabel';

/**
 * Client type field for the add client form
 * @returns {JSX.Element} A radio button group to select the client type
 * @component
 */
const ClientTypeSelect = () => {
  const { t } = useTranslation();
  return (
    <RadioButtonsWithLabel
      name="type"
      label={t('ClientsListPage.addClientModal.form.type.label')}
      tooltip={t('ClientsListPage.addClientModal.form.type.tooltip')}
      options={[
        { label: t('ClientsListPage.addClientModal.form.type.options.individual'), value: 'individual' },
        { label: t('ClientsListPage.addClientModal.form.type.options.company'), value: 'company' },
      ]}
    />
  );
};

export default ClientTypeSelect;
