import { gql } from '@apollo/client';

const adminVisibilityFragment = gql`
  fragment adminVisibilityFragment on Visibility {
    annualReport
    companyTypes
    bookkeeping
  }
`;

export default adminVisibilityFragment;
