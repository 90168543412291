import { useTranslation, Trans } from 'react-i18next';
import { useSigningProcessShoppingCartContext } from 'contexts/SigningProcessShoppingCartContext';
import concatClassNames from 'utils/classNames';
import { getPriceInformationOfService } from './ServicePrice.utils';
import OnActualCostsInfo from './OnActualCostsInfo';
import AdditionalPriceInformation from './AdditionalPriceInformation';

import classes from './ServiceEntry.module.less';

/**
 * Get text for provided payment interval
 * @param {String} paymentInterval - Payment interval
 * @param {Function} t - Translation function
 * @returns {String} translated payment interval text
 */
const getPaymentIntervalText = (paymentInterval, t) => t(`common.Item.paymentIntervalValue.${paymentInterval}`);

/**
 * It renders a translated string with dynamic values for min price, max price, and conditions for showing the prices.
 * @param {Object} props - The component props.
 * @param {number} props.minPrice - The minimum price value. If undefined or null, the min price will not be shown.
 * @param {number}[props.maxPrice - The maximum price value. If undefined or null, the max price will not be shown.
 * @param {boolean} props.rightAligned - If true, the min price will be right-aligned.
 * @returns {JSX.Element|null} A JSX element that renders the price information, or null if neither `minPrice` nor `maxPrice` is provided.
 * @component
 */
const MinMaxPriceInfo = ({ minPrice, maxPrice, rightAligned }) => {
  if (!minPrice && !maxPrice) return null;

  let minMaxTranslation = '';
  if (minPrice && maxPrice) minMaxTranslation = 'minmax';
  else if (minPrice) minMaxTranslation = 'min';
  else if (maxPrice) minMaxTranslation = 'max';

  return (
    <div
      className={concatClassNames(classes.minMaxPriceInfo, rightAligned ? classes.minMaxPriceInfoRightAligned : null)}
    >
      <Trans
        i18nKey={`sharedPackage.minMaxPriceOnActualCost.${minMaxTranslation}`}
        components={{ i: <i /> }}
        values={{
          minPrice,
          maxPrice,
        }}
      />
    </div>
  );
};

/**
 * ActualCostComponent component to show price of service paid by actual costs for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} inputParameters.priceInformations - Price information of service
 * @param {boolean} inputParameters.rightAligned - If true, the min price will be right-aligned.
 * @returns {JSX.Element} shows actual cost tag for total price of a service
 * @component
 */
export const ActualCostComponent = ({ priceInformations, isBookmarkStep, rightAligned }) => {
  const { t } = useTranslation();

  return (
    <span>
      <div className={isBookmarkStep ? '' : classes.actualPriceContainer}>
        <div>
          <OnActualCostsInfo />
          {getPaymentIntervalText(priceInformations.paymentInterval, t)}
        </div>
        <MinMaxPriceInfo
          minPrice={priceInformations.formattedMinPrice}
          maxPrice={priceInformations.formattedMaxPrice}
          rightAligned={rightAligned}
        />
      </div>
    </span>
  );
};

const generateActualCostComponentWithRightAligned =
  (rightAligned) =>
  ({ ...props }) =>
    <ActualCostComponent {...props} rightAligned={rightAligned} />;

/**
 * FixedFeeComponent component to show price included in fixed fee for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Price information of service
 * @returns {JSX.Element} shows text that price is included in fixed fee
 * @component
 */
export const FixedFeeComponent = ({ priceInformations }) => {
  const { t } = useTranslation();
  return (
    <span>
      <Trans
        i18nKey="signing.serviceOverviewStep.fixedFeeInfo"
        values={{
          paymentIntervalText: getPaymentIntervalText(priceInformations.paymentInterval, t),
        }}
      />
    </span>
  );
};

/**
 * PriceComponent component to show normal price for service overview step for signing process
 * @param {Object} inputParameters - Input parameters of the component
 * @param {ServicePriceInformation} - Price information of service
 * @returns {JSX.Element} shows price with payment interval
 * @component
 */
export const PriceComponent = ({ priceInformations }) => {
  const { t } = useTranslation();
  return (
    <span>
      {`${priceInformations.formattedTotalPriceOfService} ${getPaymentIntervalText(
        priceInformations.paymentInterval,
        t,
      )}`}
    </span>
  );
};

/**
 * PriceInformationForService component which shows the price and the related informations about the price for a service in the service overview step for the signing process (Requires signing process shopping cart context)
 * @param {Object} inputParameters - Input parameters of the component
 * @param {Object} inputParameters.item - Service object
 * @param {ActiveDiscount} inputParameters.activeDiscount - Active discount on category level
 * @param {boolean} inputParameters.rightAligned - If true, the min price will be right-aligned.
 * @returns {JSX.Element} price and price informations for a service
 * @component
 */
const PriceInformationForService = ({ item, activeDiscount, rightAligned }) => {
  const { shoppingCart } = useSigningProcessShoppingCartContext();

  if (!item || !shoppingCart) return null;

  const priceInformations = getPriceInformationOfService(item, shoppingCart, activeDiscount, {
    actualCostComponent: generateActualCostComponentWithRightAligned(rightAligned),
    fixedFeeComponent: FixedFeeComponent,
    priceComponent: PriceComponent,
  });

  return (
    <div className={classes.priceInfos}>
      <span className={classes.price}>{priceInformations.formattedPriceComponent}</span>
      <AdditionalPriceInformation
        priceInformations={priceInformations}
        activeDiscount={activeDiscount}
        officialReasonText={item.officialReasonText}
      />
    </div>
  );
};

export default PriceInformationForService;
