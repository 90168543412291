import { useQuery } from '@apollo/client';
import { userContactsQuery } from 'graphql/queries';
import useClient from './useClient';

/**
 * Custom hook to fetch contacts not associated with a specific client by client ID
 * @param {string} clientId - The ID of the client for whom to fetch unassociated contacts
 * @param {string} [fetchPolicy='cache-and-network'] - The Apollo fetch policy for the query
 * @returns {object} An object containing the contacts not in the company, loading state, and any error that occurred
 * @returns {object[]|null} return.contactsNotInCompany - Array of contacts not associated with the client, or null if not found
 * @returns {boolean} return.loading - Loading state of the query
 * @returns {object|null} return.error - Error object if the query fails, otherwise null
 */
const useContactsNotInCompany = (clientId, fetchPolicy = 'cache-and-network') => {
  const { data, error, loading } = useQuery(userContactsQuery, {
    fetchPolicy,
  });
  const { client } = useClient(clientId);
  const clientContacts = client?.contacts || [];
  const contactsNotInCompany = data?.contacts.filter(
    (contact) => !clientContacts.some((clientContact) => clientContact._id === contact._id),
  );
  return { contactsNotInCompany, error, loading };
};

export default useContactsNotInCompany;
