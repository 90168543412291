import Card from 'components/common/Card';
import CategoryInCart from 'components/user/shoppingCart/CategoryInCart';
import EmptyBox from 'components/common/EmptyBox';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import PageContainer from 'components/layout/PageContainer';
import cn from 'classnames';
import equal from 'fast-deep-equal/es6/react';
import isEmpty from 'lodash/isEmpty';
import routePaths from 'router/route-paths';
import usePreferences from 'hooks/user/usePreferences';
import { Form } from 'antd';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import {
  CategoriesContext,
  CustomPriceContext,
  VariablesContext,
  AffectedInputFieldIdsContext,
  AllApolloCategoriesContext,
  VATContext,
  ShowCategoryTotalContext,
  useVariablesContext,
  ShoppingCartRevisionContext,
  DigitalSignatureEmailProvider,
} from 'components/user/shoppingCart/context';
import { FaStar, FaSignature, FaFile } from 'react-icons/fa';
import { userVariableListQuery } from 'graphql/queries';
import { createShoppingCart, createShoppingCartRevision, createHtmlPreview } from 'graphql/methods';
import { documentModal } from 'components/user/document';
import { allInputFieldsInCategories, formikToResponse } from 'components/user/shoppingCart/utils';
import { grabFirstGQLDataResult, toastGraphQLError } from 'utils/helpers';
import { useCallback, useMemo, useRef, useState, useEffect, memo } from 'react';
import { useNavigate, generatePath, useParams, useLocation } from 'react-router-dom';
import { useComparingVariables, useShoppingCartCategories } from 'graphql/hooks';
import { useTranslation } from 'react-i18next';
import { find, intersectionBy, intersection, filter, isEqual } from 'lodash';
import { CreationMode, VATType } from 'constants/shoppingCart';
import { RichText, isElementVisible } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useStaticItemPlaceholderObject } from 'components/user/compareToGlobal/StaticItems';
import { CompareToGlobalProvider, useCompareToGlobalContext } from 'contexts/CompareToGlobalContext';
import CompareToGlobalCard from 'components/user/compareToGlobal/CompareToGlobalCard';
import { useFormikContext } from 'formik';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import SignatureCard from 'components/user/shoppingCart/SignatureCard';
import useDocumentTemplateList from 'hooks/user/useDocumentTemplateList';
import useStartDigitalSignatureDrawer from 'utils/OpenStartDigitalSignatureModal';
import { DocumentTemplatesForShoppingCartContextProvider } from 'contexts/DocumentTemplatesForShoppingCartContext';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useClient from 'ProjectCardManagement/ClientManagement/hooks/useClient';
import { sendEmailModal } from './components/SendEmail';
import { deselectItems, getInitialValues, validationSchema } from './utils';
import { ContactData } from './ContactData';
import s from './ShoppingCart.module.less';
import TotalPricing from './TotalPricing';
import ShoppingCartOptions from './ShoppingCartOptions';
import ShoppingCartLogo from './components/ShoppingCartLogo';
import ShoppingCartHelperWidget from './ShoppingCartHelperWidget';
import DocumentTypesSelect from './DocumentTypesSelect';
import ProjectInformation from './ProjectInformation';
import Buttons, { useButtonState } from './Buttons';
import ErrorList from './components/ErrorList';
import GlobalModeAlert from './components/GlobalModeAlert';

/**
 * FormComponent component is the content for the shopping cart creation/edit form (Needs a formik form wrapped around)
 * @param {Object} inputParameters - Input parameters for the component
 * @param {Object[]} inputParameters.categories - Categories available in creation form
 * @param {React.Ref} inputParameters.currentSubmitTypeRef - Reference for form submitting
 * @param {Boolean} inputParameters.isEmptyList - Is the form categories list empty
 * @param {Boolean} inputParameters.isSubmitting - Is the form currently submitting
 * @returns {JSX.Element} form content for shopping cart creation/edit page
 */
const FormComponent = ({ categories, currentSubmitTypeRef, isEmptyList, isSubmitting }) => {
  const { t } = useTranslation();
  const buttonState = useButtonState();
  const { values, setFieldValueAndTouched } = useFormikContext();
  const { showDigits, showVat, companyId } = values;
  const { client } = useClient(companyId);
  const affectedInputFieldIds = useMemo(
    () => allInputFieldsInCategories(categories)?.map(({ _id }) => _id),
    [categories],
  );
  useEffect(() => {
    deselectItems(categories, setFieldValueAndTouched, values, client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const activeCategories = useMemo(
    () =>
      categories
        .map((category) => {
          let newItems = category.items.filter((item) =>
            isElementVisible(client?.type, client?.legalForm, item.visibility),
          );
          newItems = newItems.map((item) => {
            const newInputFields = item.inputFields.map((inputField) => {
              if (Object.keys(values).includes(inputField._id)) {
                return { ...inputField, value: values[inputField._id] };
              }
              return inputField;
            });
            return { ...item, inputFields: newInputFields };
          });
          const usedIdsInFormulas = newItems.map((item) => item.recursiveFormulaInfo.usedIdsInFormula).flat();
          const newInputs = filter(category.inputFields, (inputField) =>
            find(usedIdsInFormulas, (id) => id === inputField._id),
          );

          return { ...category, items: newItems, displayInputFields: newInputs };
        })
        .filter(
          (category) =>
            category?.items?.length &&
            isElementVisible(client?.type, client?.legalForm, category.visibility) &&
            category.items.length,
        ),
    [categories, client?.legalForm, client?.type, values],
  );
  const placeholderObject = useStaticItemPlaceholderObject({ showDigits, showVat });
  const variables = useVariablesContext();
  const { isCompare } = useCompareToGlobalContext();
  const { data: documentTemplates } = useDocumentTemplateList();
  const params = useParams();
  const isAllowHistoricalDocumentTemplates = useMemo(() => params.mode === CreationMode.INDEPENDENT, [params.mode]);

  const showSignatureCard = values?.documentTemplates?.find(
    (selectedTemplate) =>
      documentTemplates?.find((template) => template._id === JSON.parse(selectedTemplate)._id)?.isSignable,
  );

  return (
    <AffectedInputFieldIdsContext.Provider value={affectedInputFieldIds}>
      <ShoppingCartOptions categories={categories} ghost iconProps={{ size: 25, color: 'gray' }} />
      <ShoppingCartLogo />
      <GlobalModeAlert />
      {isCompare ? <CompareToGlobalCard variables={variables} isCategories={!!activeCategories.length} /> : null}
      <ContactData />
      <Card
        id="category-in-cart"
        icon={<FaStar />}
        title={t('user.ShoppingCart.categoriesCardTitle')}
        className={s.shoppingCartCategoriesCard}
      >
        <AlertFromFormik />
        <RichText.PlaceholderContextProvider value={placeholderObject}>
          {activeCategories.map((category) => (
            <CategoryInCart key={category._id} category={{ ...category }} />
          ))}
        </RichText.PlaceholderContextProvider>
        {isEmptyList ? <EmptyBox>{t('user.ShoppingCart.emptyCategoryListMessage')}</EmptyBox> : null}
      </Card>
      <TotalPricing showIcon />
      <ProjectInformation />
      <DocumentTemplatesForShoppingCartContextProvider
        isAllowHistoricalDocumentTemplates={isAllowHistoricalDocumentTemplates}
      >
        <DocumentTypesSelect
          buttonState={buttonState}
          isSubmitting={isSubmitting}
          currentSubmitTypeRef={currentSubmitTypeRef}
        />
        <SignatureCard />
      </DocumentTemplatesForShoppingCartContextProvider>
      <ErrorList />
      {showSignatureCard && values.enableDigitalSignature ? (
        <Buttons
          onlyButtons
          buttonState={buttonState}
          isSubmitting={isSubmitting}
          currentSubmitTypeRef={currentSubmitTypeRef}
          schema={[
            {
              buttonProps: { icon: <FaFile /> },
              label: t('user.ShoppingCart.Buttons.saveAsDraft'),
              type: 'saveDraft',
            },
            {
              buttonProps: { icon: <FaSignature />, type: 'primary' },
              label: t('user.ShoppingCart.Buttons.saveAndStartDigitalSignature'),
              type: 'startDigitalSignature',
            },
          ]}
        />
      ) : (
        <Buttons
          onlyButtons
          buttonState={buttonState}
          isSubmitting={isSubmitting}
          currentSubmitTypeRef={currentSubmitTypeRef}
        />
      )}
    </AffectedInputFieldIdsContext.Provider>
  );
};
const FormComponentMemo = memo(FormComponent, equal);

const useInitialValues = ({ categories, dbValues, preferences, initialValues: initialValuesProps, client }) => {
  const location = useLocation();
  const params = useParams();
  const formatInitialValues = useCallback(
    () =>
      getInitialValues({
        categories,
        dbValues,
        preferences,
        initialValues: initialValuesProps,
        prevValues: location.state,
        isIndependent: params.mode === CreationMode.INDEPENDENT,
        client,
      }),
    [categories, dbValues, location.state, preferences, initialValuesProps, params.mode, client],
  );
  const [initialValues, setInitialValues] = useState(formatInitialValues);
  useEffect(() => {
    const newInitialValues = formatInitialValues();
    if (!isEqual(newInitialValues, initialValues)) setInitialValues(newInitialValues);
  }, [formatInitialValues, initialValues, params.mode]);
  return initialValues;
};

export const ShoppingCartComponent = ({
  categories,
  dbId,
  dbValues,
  initialValues: initialValuesProps,
  preferences,
  variables,
  vatVariable,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { preferences: currentPreferences } = usePreferences();
  const { data: documentTemplateList } = useDocumentTemplateList();
  const { client } = useClient(dbValues?.cart?.company?._id);
  const categoriesWithItems = categories.filter((category) => category?.items?.length);
  const isEmptyList = isEmpty(categoriesWithItems) || isEmpty(categories);
  const currentSubmitTypeRef = useRef({ type: undefined, documentTemplate: undefined }); // save or email or pdf

  const formikRef = useRef();
  window.formikRef = formikRef; // development only
  window.categories = categories; // development only
  const initialValues = useInitialValues({
    categories,
    dbValues,
    preferences,
    initialValues: initialValuesProps,
    client,
  });
  const validationSchemaWithRef = useCallback(
    (field, values) =>
      validationSchema({
        values,
        formikRef,
        categories,
        documentTemplates: documentTemplateList,
        tenantDigitalSignatureEnabled: currentPreferences?.digitalSignaturePreferences?.digitalSignatureEnabled,
        client,
      }),
    [categories, documentTemplateList, currentPreferences, client],
  );

  const [isSubmitting, setIsSubmitting] = useState();
  const { openStartDigitalSignatureDrawer } = useStartDigitalSignatureDrawer();

  const onSubmit = async (allValues) => {
    const {
      body,
      companyId,
      initializationConfigDateForCompany,
      initializationConfigDate,
      companySignees,
      contacts,
      documentTemplateBlocks: documentTemplateBlocksFormik = {},
      documentTemplates,
      emailTemplateId,
      enableDigitalSignature,
      feeType,
      hiddenNote,
      meetingAt,
      monthlyPaymentDecision,
      name,
      showDigits,
      showDiscounts,
      showPrices,
      showVat,
      signature,
      startOfContract,
      subject,
      tenantSignees,
      vatType,
      sepaEnabled,
      sepaMode,
      sepaType,
    } = allValues;
    if (isSubmitting) return;
    setIsSubmitting(true);
    const documentTemplateBlocks = Object.entries(documentTemplateBlocksFormik).map(([_id, value]) => ({
      _id,
      ...value,
    }));
    try {
      const currentType = currentSubmitTypeRef.current.type;
      const currentDocumentTemplate = currentSubmitTypeRef.current.documentTemplate;
      const { cart } = formikToResponse({ categories, values: allValues });
      const signeesData = {
        signatureMode: enableDigitalSignature ? 'DRAFT' : 'NO_DIGITAL_SIGNATURE',
        companySignees: companySignees.map((json) => JSON.parse(json)._id),
        tenantSignees,
        sepaEnabled,
        sepaMode,
        sepaType,
      };
      const shoppingCart = {
        categories: cart,
        companyId,
        contactIds: contacts.map((contact) => contact._id),
        documentTemplateBlocks,
        documentTemplates: documentTemplates.map((documentTemplate) => JSON.parse(documentTemplate)),
        feeType,
        hiddenNote,
        meetingAt,
        monthlyPaymentDecision,
        name,
        roundPriceId: preferences.roundPriceId,
        showDigits,
        showDiscounts,
        showPrices,
        showVat,
        signeesData,
        startOfContract,
        vatType,
      };
      if (params.mode === CreationMode.INDEPENDENT) {
        shoppingCart.initializationConfigDate = initializationConfigDate;
        shoppingCart.initializationConfigDateForCompany = initializationConfigDateForCompany;
      }
      const onSuccess = async ({ data, to, closeConfirmation, ...props }) => {
        const newShoppingCart = grabFirstGQLDataResult(data);
        const shoppingCartId = newShoppingCart._id;
        let route;
        let state;
        switch (to) {
          case 'NO_ROUTE':
            route = null;
            break;
          case routePaths.printShoppingCart:
            state = true;
            route = generatePath(routePaths.printShoppingCart, {
              id: shoppingCartId,
            });
            break;
          case routePaths.shoppingCartView:
            route = generatePath(routePaths.shoppingCartView, {
              id: shoppingCartId,
            });
            break;
          case routePaths.sendEmailShoppingCart:
            state = {
              data,
              subject: props?.subject,
              body: props?.body,
              bcc: props?.bcc,
              signature: props?.signature,
              shouldSendAttachments: props?.shouldSendAttachments,
              recipients: intersectionBy(contacts, props?.recipients, (e) => (typeof e === 'object' ? e._id : e)).map(
                (recipient) => ({
                  name: [recipient?.firstName, recipient?.lastName].filter(Boolean).join(' '),
                  email: recipient?.email,
                }),
              ),
            };
            route = routePaths.sendEmailShoppingCart;
            break;
          default:
            route = routePaths.shoppingCartEntries;
        }
        closeConfirmation?.(newShoppingCart);
        if (route) navigate(route, { state });
      };
      const createNewShoppingCart = async ({
        skipCreateShoppingCart,
        to = routePaths.shoppingCartView,
        ...props
      } = {}) => {
        const createData = {
          shoppingCart,
        };
        return (skipCreateShoppingCart ? Promise.resolve({ data: createData }) : createShoppingCart(createData)).then(
          ({ data }) => onSuccess({ data, to, ...props }),
        );
      };

      const createNewShoppingCartRevision = async ({
        skipCreateShoppingCart,
        to = routePaths.shoppingCartView,
        ...props
      } = {}) => {
        const createData = {
          prevCartId: dbId,
          shoppingCart,
        };
        return (
          skipCreateShoppingCart ? Promise.resolve({ data: createData }) : createShoppingCartRevision(createData)
        ).then(({ data }) => onSuccess({ data, to, ...props }));
      };

      if (currentType === 'save' || currentType === 'saveDraft') {
        if (dbValues) {
          await createNewShoppingCartRevision();
        } else {
          await createNewShoppingCart();
        }
      }
      if (currentType === 'startDigitalSignature') {
        const openEmailDrawerForDigitalSignatureStart = (data) => {
          const onCloseDrawer = () => {
            navigate(
              generatePath(routePaths.shoppingCartView, {
                id: data._id,
              }),
            );
          };
          openStartDigitalSignatureDrawer({
            shoppingCart: data,
            preferences: currentPreferences,
            translation: t,
            onCloseDrawer,
          });
        };
        if (dbValues) {
          await createNewShoppingCartRevision({
            to: 'NO_ROUTE',
            closeConfirmation: openEmailDrawerForDigitalSignatureStart,
          });
        } else {
          await createNewShoppingCart({
            to: 'NO_ROUTE',
            closeConfirmation: openEmailDrawerForDigitalSignatureStart,
          });
        }
      }
      if (currentType === 'email') {
        const openEmailDrawerForDigitalSignatureStart = (data) => {
          const onCloseDrawer = () => {
            navigate(
              generatePath(routePaths.shoppingCartView, {
                id: data._id,
              }),
            );
          };
          const onSubmitSendEmail = (values, onCloseConfirm) => {
            const state = {
              shoppingCartId: data._id,
              data,
              subject: values?.subject,
              body: values?.body,
              bcc: values?.bcc,
              signature: values?.signature,
              shouldSendAttachments: values?.shouldSendAttachments,
              recipients: intersectionBy(contacts, values?.recipients, (e) => (typeof e === 'object' ? e._id : e)).map(
                (recipient) => ({
                  name: [recipient?.firstName, recipient?.lastName].filter(Boolean).join(' '),
                  email: recipient?.email,
                }),
              ),
            };
            navigate(routePaths.sendEmailShoppingCart, { state });
            onCloseConfirm();
          };
          const allRecipients = contacts.filter((contact) => contact?.email);
          const recipients = (() => {
            const allRecipientIds = allRecipients.map((r) => r._id);
            const formikRecipients = intersection(allValues.recipients, allRecipientIds);
            return formikRecipients.length ? formikRecipients : allRecipientIds;
          })();
          sendEmailModal({
            allRecipients,
            body,
            emailTemplateId,
            initialRecipients: recipients,
            onCloseAfter: onCloseDrawer,
            onOk: onSubmitSendEmail,
            shoppingCart,
            signature,
            subject,
          });
        };
        if (dbValues) {
          await createNewShoppingCartRevision({
            to: 'NO_ROUTE',
            closeConfirmation: openEmailDrawerForDigitalSignatureStart,
          });
        } else {
          await createNewShoppingCart({
            to: 'NO_ROUTE',
            closeConfirmation: openEmailDrawerForDigitalSignatureStart,
          });
        }
      }

      if (currentType === 'html') {
        documentModal({
          value: createHtmlPreview({
            shoppingCart,
            documentTemplate: currentDocumentTemplate,
          }),
          type: 'html',
        });
      }

      if (currentType === 'print') {
        if (dbValues) {
          await createNewShoppingCartRevision({ to: routePaths.printShoppingCart });
        } else {
          await createNewShoppingCart({ to: routePaths.printShoppingCart });
        }
      }
    } catch (e) {
      toastGraphQLError(e);
    }
    window.setTimeout(() => setIsSubmitting(false), 0);
  };
  const documentTemplateBlocks = useMemo(
    () => dbValues?.cart?.documentTemplateBlocks?.filter((block) => block?.props),
    [dbValues?.cart?.documentTemplateBlocks],
  );
  const customPriceContextProviderValue = useMemo(
    () => ({ allowCustomPrices: preferences.allowCustomPrices, showCalculatedPrice: preferences.showCalculated }),
    [preferences.allowCustomPrices, preferences.showCalculated],
  );
  const shoppingCartRevision = useMemo(() => dbValues?.cart ?? {}, [dbValues?.cart]);
  return (
    <ShowCategoryTotalContext.Provider value={preferences.showCategoryTotal}>
      <CustomPriceContext.Provider value={customPriceContextProviderValue}>
        <VATContext.Provider value={vatVariable}>
          <VariablesContext.Provider value={variables}>
            <CategoriesContext.Provider value={categories}>
              <ShoppingCartRevisionContext.Provider value={shoppingCartRevision}>
                <I18nFormik
                  ref={formikRef}
                  reInitOnSubmit={false}
                  initialValues={initialValues}
                  enableReinitialize
                  onSubmit={onSubmit}
                  validationSchema={validationSchemaWithRef}
                  disableIsValidating // main prop that speeds up shopping cart validation
                >
                  <Form layout="vertical">
                    <FormComponentMemo
                      categories={categories}
                      currentSubmitTypeRef={currentSubmitTypeRef}
                      documentTemplateBlocks={documentTemplateBlocks}
                      isEmptyList={isEmptyList}
                      isSubmitting={isSubmitting}
                    />
                  </Form>
                </I18nFormik>
                {isSubmitting &&
                  (currentSubmitTypeRef.current.type === 'save' ||
                    currentSubmitTypeRef.current.type === 'saveDraft' ||
                    currentSubmitTypeRef.current.type === 'email' ||
                    currentSubmitTypeRef.current.type === 'print' ||
                    currentSubmitTypeRef.current.type === 'startDigitalSignature') && <LoadingOverlay isFixed />}
              </ShoppingCartRevisionContext.Provider>
            </CategoriesContext.Provider>
          </VariablesContext.Provider>
        </VATContext.Provider>
      </CustomPriceContext.Provider>
    </ShowCategoryTotalContext.Provider>
  );
};

const ShoppingCartComponentMemo = memo(ShoppingCartComponent, equal);

const ShoppingCartComponentContainer = (props) => {
  const { dbId, preferences, categories } = props;
  if (dbId)
    return (
      <DigitalSignatureEmailProvider>
        <ShoppingCartComponentMemo {...props} />
      </DigitalSignatureEmailProvider>
    );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialValues, setInitialValues] = useState();
  if (initialValues?.validCompany)
    return (
      <DigitalSignatureEmailProvider>
        <ShoppingCartComponentMemo {...props} initialValues={initialValues} />
      </DigitalSignatureEmailProvider>
    );
  return (
    <div>
      <I18nFormik
        onSubmit={(values) => setInitialValues(values)}
        initialValues={{
          feeType: preferences?.feeType,
          showPrices: preferences?.showPrices,
          showDiscounts: preferences?.showDiscounts,
          showVat: true,
          vatType: VATType.PERSONAL,
          hiddenNote: '',
        }}
      >
        {({ handleSubmit }) => (
          <>
            <ShoppingCartOptions categories={categories} ghost iconProps={{ size: 25, color: 'gray' }} />
            <ShoppingCartLogo />
            <ContactData onSubmitForm={handleSubmit} />
          </>
        )}
      </I18nFormik>
    </div>
  );
};

export const ShoppingCart = ({ dbId, dbValues, dbLoading }) => {
  const { id: shoppingCartId, mode } = useParams();
  const fetchShoppingCartId = useMemo(
    () => (mode === CreationMode.INDEPENDENT ? shoppingCartId : undefined),
    [mode, shoppingCartId],
  );
  const {
    preferences,
    loading: preferencesLoading,
    error: errorPreferences,
  } = usePreferences({ shoppingCartId: fetchShoppingCartId });
  const {
    data: variables,
    loading: loadingData,
    error: errorVariables,
  } = useComparingVariables({
    shoppingCartId: fetchShoppingCartId,
    query: userVariableListQuery,
  });

  const {
    data: categories,
    getAllApolloCategories,
    loading,
    error: errorCategories,
  } = useShoppingCartCategories({
    fetchPolicy: 'network-only',
    shoppingCartId: fetchShoppingCartId,
  });

  const vatVariable = useMemo(() => find(variables, { name: 'vatPercent' }), [variables]);
  const someLoading = loading || loadingData || dbLoading || preferencesLoading;
  return (
    <AllApolloCategoriesContext.Provider value={getAllApolloCategories}>
      <PageContainer
        className={cn(s.pageContainer, 'shopping-cart')}
        title=""
        left={<ShoppingCartHelperWidget buttonClassName={s.helperWidgetButton} />}
      >
        <div className="xs-mt-20">
          <GraphQLLoadingWrapper
            data={categories && preferences && variables && (!shoppingCartId || (shoppingCartId && dbValues))}
            loading={someLoading}
            error={errorCategories || errorVariables || errorPreferences}
          >
            <ShoppingCartComponentContainer
              categories={categories}
              dbId={dbId}
              dbValues={dbValues}
              preferences={preferences}
              variables={variables}
              vatVariable={vatVariable}
            />
          </GraphQLLoadingWrapper>
        </div>
      </PageContainer>
    </AllApolloCategoriesContext.Provider>
  );
};

export default (props) => (
  <CompareToGlobalProvider>
    <ShoppingCart {...props} />
  </CompareToGlobalProvider>
);
