import { useCallback, useState, Fragment } from 'react';
import { Typography, Checkbox, Popover } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useSigningProcessShoppingCartContext } from 'contexts/SigningProcessShoppingCartContext';
import CollapsableCard from '../CollapsableCard';
import InputFieldValuesFromCategory from '../InputFieldValuesFromCategory';
import {
  ActualCostComponent,
  PriceComponent,
  FixedFeeComponent,
} from '../ServiceOverviewStep/ServiceEntry/PriceInformationForService';
import { getPriceInformationOfService } from '../ServiceOverviewStep/ServiceEntry/ServicePrice.utils';
import AdditionalPriceInformation from '../ServiceOverviewStep/ServiceEntry/AdditionalPriceInformation';
import classes from './BookmarkedItemsOverview.module.less';

/**
 * Renders the list of bookmarked items and lets you select them.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.category - The category containing the bookmarked items.
 * @param {Function} props.onBookmarkedItemsSelected - Callback function to be called when the user selects a bookmarked item.
 * @param {boolean} props.disabled - Flag indicating whether the form is disabled.
 * @param {boolean} props.isTenant - Flag indicating whether the user is a tenant.
 * @returns {ReactElement} A form component for SEPA mandate decision.
 */
const BookmarkedItemsOverview = ({ category, onBookmarkedItemsSelected, disabled, isTenant }) => {
  const context = useSigningProcessShoppingCartContext();

  const { bookmarkedItems } = category;

  const [selectedBookmarkedItems, setSelectedBookmarkedItems] = useState(
    bookmarkedItems.map((item) => ({ selected: item.wasSelected || false, id: item._id })),
  );

  const onOrderBookmarkedItemChange = useCallback(
    (bookmarkedItemId, checked) => {
      const newSelectedBookmarkedItems = selectedBookmarkedItems.map((item) => {
        if (item.id === bookmarkedItemId) {
          return { ...item, selected: checked };
        }
        return item;
      });
      setSelectedBookmarkedItems(newSelectedBookmarkedItems);
      onBookmarkedItemsSelected(
        category._id,
        newSelectedBookmarkedItems
          .filter((item) => item.selected)
          .map((item) => bookmarkedItems.find((i) => i._id === item.id)),
      );
    },
    [bookmarkedItems, category, onBookmarkedItemsSelected, selectedBookmarkedItems],
  );

  if (!context) return null;

  return (
    <div>
      {bookmarkedItems.map((bookmarkedItem) => {
        return (
          <BookmarkedItemElement
            bookmarkedItem={bookmarkedItem}
            onOrderBookmarkedItemChange={onOrderBookmarkedItemChange}
            category={category}
            disabled={disabled}
            isTenant={isTenant}
            key={bookmarkedItem._id}
          />
        );
      })}
    </div>
  );
};

/**
 * Checkbox component for selecting a bookmarked item
 * @param {Object} props - The component props
 * @param {boolean} props.disabled - Whether the checkbox is disabled
 * @param {Object} props.bookmarkedItem - The bookmarked item object
 * @param {string} props.bookmarkedItem.id - The ID of the bookmarked item
 * @param {boolean} props.bookmarkedItem.wasSelected - The default checked state of the checkbox
 * @param {Object} props.bookmarkedItem.texts - The texts associated with the bookmarked item
 * @param {string} props.bookmarkedItem.texts.name - The name of the service to be displayed in the checkbox
 * @param {function} props.onOrderBookmarkedItemChange - Callback function to handle changes when the checkbox is toggled
 * @param {boolean} props.onOrderBookmarkedItemChange.bookmarkedItemId - The ID of the bookmarked item
 * @param {boolean} props.onOrderBookmarkedItemChange.isSelected - Whether the item was checked or unchecked
 * @param {boolean} props.showPopover - Optional: Whether to show a popover with additional information (Default: false)
 * @returns {JSX.Element} The rendered Checkbox component with a label
 * @component
 */
const BookmarkedItemCheckbox = ({ disabled, bookmarkedItem, onOrderBookmarkedItemChange, showPopover = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'signing.orderBookmarkedItemsStep' });

  const Wrapper = showPopover ? Popover : Fragment;
  const popoverContent = <div className={classes.tenantPopover}>{t('warning.tenantPopover')}</div>;

  return (
    <Wrapper content={popoverContent} trigger="hover" placement="topLeft">
      <Checkbox
        className={classes.checkbox}
        name={bookmarkedItem.id}
        disabled={disabled}
        defaultChecked={bookmarkedItem.wasSelected}
        onChange={(event) => onOrderBookmarkedItemChange(bookmarkedItem._id, event.target.checked)}
      >
        <Typography.Text disabled={disabled}>
          <Trans t={t} i18nKey="options.yes" values={{ serviceName: bookmarkedItem.texts.name }}>
            <strong>Yes</strong>, I want to book this service
          </Trans>
        </Typography.Text>
      </Checkbox>
    </Wrapper>
  );
};

/**
 * Bookmarked service component for the list of bookmarked items in the signature process
 * @param {object} inputProperties - Input properties of the component
 * @param {object} inputProperties.bookmarkedItem - The bookmarked item to be displayed
 * @param {(serviceId: string, checked: boolean) => void} inputProperties.onOrderBookmarkedItemChange - Callback function to be called when the user selects a bookmarked item
 * @param {object} inputProperties.category - The category containing the bookmarked items
 * @param {boolean} inputProperties.disabled - Flag indicating whether the form is disabled
 * @param {boolean} props.isTenant - Flag indicating whether the user is a tenant
 * @returns {JSX.Element} Orderable bookmarked service
 * @component
 */
const BookmarkedItemElement = ({ bookmarkedItem, onOrderBookmarkedItemChange, category, disabled, isTenant }) => {
  const context = useSigningProcessShoppingCartContext();
  const { t } = useTranslation('translation', { keyPrefix: 'signing.orderBookmarkedItemsStep' });

  if (!context) return null;

  const { shoppingCart } = context;
  const { discount } = category;

  const priceInformations = getPriceInformationOfService(
    bookmarkedItem,
    shoppingCart,
    discount,
    {
      actualCostComponent: ActualCostComponent,
      fixedFeeComponent: FixedFeeComponent,
      priceComponent: PriceComponent,
    },
    shoppingCart.showVat,
    true,
  );

  return (
    <CollapsableCard
      key={bookmarkedItem.texts.name}
      title={`${category.texts.name}: ${bookmarkedItem.texts.name}`}
      openByDefault
    >
      <BookmarkedItemCheckbox
        showPopover={isTenant}
        disabled={disabled}
        bookmarkedItem={bookmarkedItem}
        onOrderBookmarkedItemChange={onOrderBookmarkedItemChange}
      />
      <div className={classes.inputFieldValues}>
        <InputFieldValuesFromCategory category={category} />
      </div>
      {bookmarkedItem.texts.benefits ? (
        <div className={classes.benefits}>
          <Typography.Text className={classes.headline}>{t`benefitsHeadline`}</Typography.Text>
          <div
            className={classes.benefitsText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: bookmarkedItem.texts.benefits }}
          />
        </div>
      ) : null}
      <div className={classes.price}>
        <Typography.Text className={classes.headline}>{t`price`}</Typography.Text>
        <div>{priceInformations.formattedPriceComponent}</div>
        <div className={classes.priceInfo}>
          <AdditionalPriceInformation priceInformations={priceInformations} activeDiscount={discount} />
        </div>
      </div>
    </CollapsableCard>
  );
};

export default BookmarkedItemsOverview;
