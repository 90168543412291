import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import getCountryName from 'ProjectCardManagement/ClientManagement/viewModel/getCountryName';
import { getAnnualReportLabel } from 'utils/helpers';
import classes from './ClientDetails.module.less';

/**
 * List of details of the client
 * @param {object} inputProperties - The input properties for the component
 * @param {object} inputProperties.client - The client to display
 * @param {string} inputProperties.client.name - The name of the client
 * @param {string} inputProperties.client.identifier - The identifier of the client
 * @param {object} inputProperties.client.address - The address of the client
 * @param {string} inputProperties.client.address.addition - The addition to the address
 * @param {string} inputProperties.client.address.street - The street of the address
 * @param {string} inputProperties.client.address.houseNumber - The house number of the address
 * @param {string} inputProperties.client.address.cityCode - The city code of the address
 * @param {string} inputProperties.client.address.city - The city of the address
 * @param {string} inputProperties.client.address.country - The country of the address
 * @param {string} inputProperties.client.type - The type of the client
 * @param {object} inputProperties.client.importFields - The import fields of the client
 * @param {string} inputProperties.client.importFields.commercialObject - The commercial object of the client
 * @param {object} inputProperties.client.legalForm - Legal form of the client
 * @param {string} inputProperties.client.legalForm.companyTypeName - The company type name of the client
 * @param {string} inputProperties.client.legalForm.annualReport - The annual report choice of the client
 * @param {string} inputProperties.client.legalForm.bookkeeping - The bookkeeping choice of the client
 * @returns {JSX.Element} Details of a client
 * @component
 */
const ClientDetails = ({ client }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });
  const annualReportLabel = getAnnualReportLabel(client.legalForm?.annualReport, client.legalForm?.country);

  if (!client) return null;
  return (
    <div className={classes.client_details}>
      <Row>
        <Col className={classes.client_details_label}>{t('label.name')}</Col>
        <Col className={classes.client_details_value}>{client.name}</Col>
      </Row>
      <Row>
        <Col className={classes.client_details_label}>{t('label.clientNumber')}</Col>
        <Col className={classes.client_details_value}>{client.identifier}</Col>
      </Row>
      <Row>
        <Col className={classes.client_details_label}>{t('label.address')}</Col>
        <Col className={classes.client_details_value}>
          {client?.address?.addition ? (
            <>
              {client?.address?.addition} <br />
            </>
          ) : null}
          {client?.address?.street} {client?.address?.houseNumber}
          <br />
          {client?.address?.cityCode} {client?.address?.city}
          <br />
          {client?.address?.country ? getCountryName(client?.address?.country) : null}
        </Col>
      </Row>
      <Row>
        <Col className={classes.client_details_label}>{t('label.clientType')}</Col>
        <Col className={classes.client_details_value}>{t(`clientType.${client.type}`)}</Col>
      </Row>
      {client.type === 'company' ? (
        <>
          <Row>
            <Col className={classes.client_details_label}>{t('label.commercialObject')}</Col>
            <Col className={classes.client_details_value}>{client.importFields.commercialObject}</Col>
          </Row>
          {client.legalForm?.companyTypeName ? (
            <Row>
              <Col className={classes.client_details_label}>{t('label.companyTypeName')}</Col>
              <Col className={classes.client_details_value}>{client.legalForm.companyTypeName}</Col>
            </Row>
          ) : null}
          {annualReportLabel ? (
            <Row>
              <Col className={classes.client_details_label}>{t('label.annualReport')}</Col>
              <Col className={classes.client_details_value}>{t(`annualReport.${annualReportLabel}`)}</Col>
            </Row>
          ) : null}
          {client.legalForm?.bookkeeping ? (
            <Row>
              <Col className={classes.client_details_label}>{t('label.bookkeeping')}</Col>
              <Col className={classes.client_details_value}>{t(`bookkeeping.${client.legalForm.bookkeeping}`)}</Col>
            </Row>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default ClientDetails;
