import { gql } from '@apollo/client';

const adminLegalFormsFragment = gql`
  fragment adminLegalFormsFragment on CompanyLegalForm {
    country
    companyTypeName
    companyType
    annualReport
    bookkeeping
    manualMigration
  }
`;

export default adminLegalFormsFragment;
