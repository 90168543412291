import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';
import { toastGraphQLError } from 'utils/helpers';
import { message } from 'antd';
import generateMessageConfig from 'components/common/CloseableMessage';
import deleteContact from './deleteContact';

/**
 * Deletes contact with confirmation
 * @param {string} clientId - id of client
 * @param {string} contactId - id of contact
 */
const deleteContactWithConfirmation = async (clientId, contactId) => {
  confirmModal({
    okText: i18n.t('ClientsListPage.deleteContactModal.ok'),
    cancelText: i18n.t('ClientsListPage.deleteContactModal.cancel'),
    title: i18n.t('ClientsListPage.deleteContactModal.title'),
    content: <p style={{ color: 'red', fontWeight: 'bold' }}>{i18n.t('ClientsListPage.deleteContactModal.content')}</p>,
    okType: 'danger',
    onOk: () =>
      deleteContact(clientId, contactId)
        .then(
          message.success(
            generateMessageConfig(
              i18n.t('ClientsListPage.deleteContactModal.success'),
              `deleteContactSuccess_{clientId}`,
              5,
            ),
          ),
        )
        .catch(toastGraphQLError),
    maskClosable: true,
    width: '800px',
  });
};

export default deleteContactWithConfirmation;
