import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { pick } from 'lodash';
import ShoppingCartFormItem from '../../components/ShoppingCartFormItem';
import CompanyCard from './CompanyCard';
import { checkIfLegalFormMigrationIsRequired } from './utils';

const COMPANY_ID_FIELDNAME = 'companyId';
const CONTACTS_FIELDNAME = 'contacts';

/**
 * Component for managing and displaying the selected company in a form.
 * @param {object} props - Component properties.
 * @param {function} props.onSubmitForm - Callback to trigger form submission after changes.
 * @returns {JSX.Element} A form item for selecting or displaying a company.
 * @component
 */
const CompanyField = ({ onSubmitForm }) => {
  const { values, setValues } = useFormikContext();
  // const [visible, showModal, closeModal] = useModal();
  const { companyId, initializationConfigDateForCompany, contacts } = useMemo(
    () => ({
      companyId: values?.companyId,
      initializationConfigDateForCompany: values?.initializationConfigDateForCompany,
      contacts: CONTACTS_FIELDNAME ? values?.[CONTACTS_FIELDNAME] ?? [] : [],
    }),
    [values],
  );

  const onChange = useCallback(
    (newCompany, isReplaceContacts) => {
      const newValues = {
        [COMPANY_ID_FIELDNAME]: newCompany._id,
        validCompany: !checkIfLegalFormMigrationIsRequired(newCompany),
      };
      if (isReplaceContacts)
        newValues[CONTACTS_FIELDNAME] = newCompany.contacts.map((contact) => pick(contact, ['_id', 'email']));
      setValues((state) => ({ ...state, ...newValues }), true);
      onSubmitForm();
    },
    [onSubmitForm, setValues],
  );
  return (
    <ShoppingCartFormItem name={COMPANY_ID_FIELDNAME} label="" className="form-item-no-margin">
      <CompanyCard
        companyId={companyId}
        contacts={contacts}
        onChange={onChange}
        initializationConfigDateForCompany={initializationConfigDateForCompany}
      />
    </ShoppingCartFormItem>
  );
};
export default CompanyField;
