import { memo, useMemo } from 'react';
import equal from 'fast-deep-equal';
import StaticItems from 'components/user/compareToGlobal/StaticItems';
import { RichText } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { PaymentInterval } from 'constants/item';
import { useTranslation } from 'react-i18next';
import { useCachedQuery } from 'graphql/utils';
import { getPlaceholderObjectByIdQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import ShortKeyValueBlock from 'components/common/ShortKeyValueBlock';
import classes from './CategoryInView.module.less';
import formattedInputValue from './components/InputFieldsValuePreparation';
import BookedServices from './BookedServices';
import TotalPricing from './TotalPricing';
import formattedPeriodValue from './components/formattedPeriodValue';

const buildCategoryInfoBlockKeyValuePairs = ({
  discount,
  inputFields,
  isStartOfService,
  showPeriod,
  startPeriod,
  endPeriod,
  showDigits,
  startOfService,
  t,
}) => {
  const keyValuePairs = [];

  if (isStartOfService)
    keyValuePairs.push({
      key: `${t('user.ShoppingCart.Category.ValidationLabels.startOfService')}:`,
      value: startOfService,
    });

  if (showPeriod && startPeriod) {
    keyValuePairs.push({
      key: t('user.ShoppingCart.Category.period.label'),
      value: formattedPeriodValue({ startPeriod, endPeriod, t }),
    });
  } else {
    keyValuePairs.push({
      key: t('user.ShoppingCart.Category.period.label'),
      value: <span className="info-text">{t('user.ShoppingCart.Category.period.empty')}</span>,
    });
  }

  if (discount)
    keyValuePairs.push({ key: `${t('user.ShoppingCart.Category.ValidationLabels.discount')}:`, value: discount.name });
  if (inputFields) {
    inputFields
      .map((inputField) => {
        return { key: `${inputField.name}:`, value: formattedInputValue({ inputField, showDigits, t }) };
      })
      .forEach((keyValuePair) => {
        keyValuePairs.push(keyValuePair);
      });
  }
  return keyValuePairs;
};

/**
 * Filters out items from the bookmarkedItemsUnfiltered array that are already booked.
 *
 * @param {Array} bookmarkedItemsUnfiltered - The array of bookmarked items to be filtered.
 * @param {Array} items - The array of items to check against.
 * @returns {Array} - The filtered array of bookmarked items.
 */
const filterBookedBookmarkedItems = (bookmarkedItemsUnfiltered, items) => {
  return bookmarkedItemsUnfiltered.filter((item) => {
    if (items.find((i) => i._id === item._id && i.wasBookmarkedItem)) {
      return false;
    }
    return true;
  });
};

const Category = ({ category, migrated, showPrices, feeType, showDigits, vatType, showVat }) => {
  const { t } = useTranslation();
  const {
    name,
    startOfService,
    showPeriod,
    startPeriod,
    endPeriod,
    inputFields,
    discount,
    items,
    bookmarkedItems: bookmarkedItemsUnfiltered,
    staticItems,
    introForStaticItem,
    outroForStaticItem,
  } = category;

  const bookmarkedItems = useMemo(
    () => filterBookedBookmarkedItems(bookmarkedItemsUnfiltered, items),
    [bookmarkedItemsUnfiltered, items],
  );

  const isStartOfService = useMemo(
    () =>
      startOfService && [...items, ...bookmarkedItems].find((item) => item.paymentInterval !== PaymentInterval.ONE_OFF),
    [bookmarkedItems, items, startOfService],
  );

  const categoryInfoBlockKeyValuePairs = buildCategoryInfoBlockKeyValuePairs({
    discount,
    inputFields,
    isStartOfService,
    showPeriod,
    startPeriod,
    endPeriod,
    showDigits,
    startOfService,
    t,
  });

  return (
    <div className={classes.category}>
      <h1 className="margin-bottom-0">{name}</h1>
      <div className={classes.categoryBody}>
        <ShortKeyValueBlock
          keyPrefix={name}
          keyValuePairs={categoryInfoBlockKeyValuePairs}
          className={classes.categoryInfoBlock}
        />
        {[
          items.length && <BookedServices items={items} showDigits={showDigits} />,
          bookmarkedItems.length && <BookedServices items={bookmarkedItems} isBookmarked showDigits={showDigits} />,
          staticItems.length && (
            <StaticItems
              introForStaticItem={introForStaticItem}
              outroForStaticItem={outroForStaticItem}
              staticItems={staticItems}
              showPrices={showPrices}
              className={classes.shoppingCartStaticItems}
              showVat={showVat}
              showDigits={showDigits}
            />
          ),
        ].filter(Boolean)}
        {migrated ? null : (
          <TotalPricing
            discount={discount}
            feeType={feeType}
            isCategoryLevel
            items={category.items}
            parent={category}
            showDigits={showDigits}
            title={t('viewer.ShoppingCartView.BookedServices.categoryTotals')}
            total={category.total}
            vatType={vatType}
          />
        )}
      </div>
    </div>
  );
};

const CategoryInView = ({
  shoppingCartId,
  categories,
  feeType,
  migrated,
  showPrices,
  showDigits,
  vatType,
  showVat,
}) => {
  const { data, ...rest } = useCachedQuery(getPlaceholderObjectByIdQuery, {
    variables: { _id: shoppingCartId },
    fetchPolicy: 'cache-and-network',
  });
  const placeholderObject = useMemo(
    () =>
      data && {
        ...JSON.parse(grabFirstGQLDataResult(data)),
      },
    [data],
  );

  return (
    <GraphQLLoadingWrapper data={data} {...rest}>
      <RichText.PlaceholderContextProvider value={placeholderObject}>
        {categories.map((category) => (
          <Category
            category={category}
            feeType={feeType}
            key={category._id}
            migrated={migrated}
            showDigits={showDigits}
            showPrices={showPrices}
            vatType={vatType}
            showVat={showVat}
          />
        ))}
      </RichText.PlaceholderContextProvider>
    </GraphQLLoadingWrapper>
  );
};

export default memo(CategoryInView, equal);
