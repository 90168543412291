import { Mentions } from '@JavaScriptSuperstars/kanzleipilot-shared';
import apollo from 'graphql/apollo';
import { adminCategoryListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';

/**
 * The possible conflicts in company types between items and categories
 */
export const COMPANY_TYPE_CONFLICTS = {
  NONE: 'NONE',
  REFERENCING_ITEMS_CONFLICT: 'REFERENCING_ITEMS_CONFLICT',
  REFERENCED_ITEMS_CONFLICT: 'REFERENCED_ITEMS_CONFLICT',
  REFERENCING_AND_REFERENCED_ITEMS_CONFLICT: 'REFERENCING_AND_REFERENCED_ITEMS_CONFLICT',
};

export const isCategoryItemComplex = ({ inputFields, pricingFormulaExtended: { isComplex } = {} }) =>
  isComplex || !!inputFields.find((inputField) => inputField.options.find((option) => option.valueExtended.isComplex));

/**
 * Get the item and the parent category
 * @param {object[]} categories - The categories from which the item and category should be retrieved
 * @param {string} itemId - Id of relevant item
 * @returns {object} The category and item
 */
export const getItemAndParentCategory = (categories, itemId) => {
  if (!categories) return { category: undefined, item: undefined };

  let item;

  const parentCategory = categories.find((category) => {
    item = category.items.find((i) => i._id === itemId);
    return item;
  });

  return { category: parentCategory, item };
};

/**
 * Get the item and the parent category from the cache
 * @param {boolean} isLibrary - Is this function called on common library data
 * @param {string} itemId - Id of the item
 * @returns {object} The category and item
 */
export const getCategoryAndItemFromCache = (isLibrary, itemId) => {
  const categories = grabFirstGQLDataResult(
    apollo.readQuery({
      query: adminCategoryListQuery,
      variables: { isLibrary },
    }),
  );

  return getItemAndParentCategory(categories, itemId);
};

/**
 * Get the items referencing the current item
 * @param {string} itemId - The item id
 * @param {object} category - The category
 * @returns {object[]} The items referencing the current item
 */
export const getItemsReferencingCurrentItem = (itemId, category) => {
  try {
    return category.items.filter(({ pricingFormula }) => {
      const formula = Mentions.richTextToFormula(pricingFormula, { hideMentionText: true });
      return !!Mentions.getInputFieldIdsFromFormula(formula).find((_id) => _id === itemId);
    });
  } catch {
    return [];
  }
};

/**
 * Retrieves the items referenced by the current item.
 * @param {Object} item - The current item.
 * @param {Object} category - The category object.
 * @returns {Array} - The items referenced by the current item.
 */
export const getItemsReferencedByCurrentItem = (item, category) => {
  if (!item?.recursiveFormulaInfo || !category) return [];
  const referencedItemIds = item.recursiveFormulaInfo.formulaRequiredItemIds;
  return referencedItemIds.map((itemId) => category.items.find((itemOfCategory) => itemOfCategory._id === itemId));
};

/**
 * Retrieves the items involved in a formula.
 * @param {Object} item - The item for which to retrieve the involved items.
 * @param {string} category - The category of the item.
 * @returns {Object} - The items referencing the current item and the items referenced by the current item.
 */
export const getItemsInvolvedInFormula = (item, category) => {
  const itemsReferencingItem = getItemsReferencingCurrentItem(item._id, category);
  const itemsReferencedByItem = getItemsReferencedByCurrentItem(item, category);
  return { itemsReferencingItem, itemsReferencedByItem };
};

/**
 * Checks if any item in the subset array is not included in the superset array.
 * @param {Array} subset - The array to check if its items are included in the superset.
 * @param {Array} superset - The array that should include all items from the subset.
 * @returns {boolean} True if at least one item in the subset is not in the superset, otherwise false.
 */
export const isSubsetNotFullyIncluded = (subset, superset) => subset.some((item) => !superset.includes(item));

/**
 * Checks if there are conflicts in company types, bookkeeping, or annual reports between referencing items and new visibility settings.
 * @param {Array} referencingItems - An array of items containing current visibility settings.
 * @param {Object} newVisibility - The new visibility settings to compare.
 * @param {Array} [newVisibility.companyTypes=[]] - New company types to compare against.
 * @param {Array} [newVisibility.bookkeeping=[]] - New bookkeeping settings to compare against.
 * @param {Array} [newVisibility.annualReport=[]] - New annual report settings to compare against.
 * @returns {boolean} True if there are any conflicts, otherwise false.
 */
export const haveReferencingItemsConflictingCompanyTypes = (referencingItems, newVisibility) => {
  const {
    companyTypes: newCompanyTypes = [],
    bookkeeping: newBookkeeping = [],
    annualReport: newAnnualReport = [],
  } = newVisibility;

  return referencingItems.some((item) => {
    const {
      companyTypes: itemCompanyTypes = [],
      bookkeeping: itemBookkeeping = [],
      annualReport: itemAnnualReport = [],
    } = item.visibility || {};

    const hasCompanyTypesConflict = isSubsetNotFullyIncluded(itemCompanyTypes, newCompanyTypes);
    const hasBookkeepingConflict = isSubsetNotFullyIncluded(itemBookkeeping, newBookkeeping);
    const hasAnnualReportConflict = isSubsetNotFullyIncluded(itemAnnualReport, newAnnualReport);

    return hasCompanyTypesConflict || hasBookkeepingConflict || hasAnnualReportConflict;
  });
};

/**
 * Checks if there are conflicts in company types, bookkeeping, or annual reports between new visibility settings and referenced items.
 * @param {Array} referencedItems - An array of items containing current visibility settings.
 * @param {Object} newVisibility - The new visibility settings to compare.
 * @param {Array} [newVisibility.companyTypes=[]] - New company types to compare against.
 * @param {Array} [newVisibility.bookkeeping=[]] - New bookkeeping settings to compare against.
 * @param {Array} [newVisibility.annualReport=[]] - New annual report settings to compare against.
 * @returns {boolean} True if there are any conflicts, otherwise false.
 */
export const haveReferencedItemsConflictingCompanyTypes = (referencedItems, newVisibility) => {
  const {
    companyTypes: newCompanyTypes = [],
    bookkeeping: newBookkeeping = [],
    annualReport: newAnnualReport = [],
  } = newVisibility;

  return referencedItems.some((item) => {
    const {
      companyTypes: itemCompanyTypes = [],
      bookkeeping: itemBookkeeping = [],
      annualReport: itemAnnualReport = [],
    } = item.visibility || {};

    const hasCompanyTypesConflict = isSubsetNotFullyIncluded(newCompanyTypes, itemCompanyTypes);
    const hasBookkeepingConflict = isSubsetNotFullyIncluded(newBookkeeping, itemBookkeeping);
    const hasAnnualReportConflict = isSubsetNotFullyIncluded(newAnnualReport, itemAnnualReport);

    return hasCompanyTypesConflict || hasBookkeepingConflict || hasAnnualReportConflict;
  });
};

/**
 * Checks if there is a conflict in company types for the given item
 * @param {Array} newItemCompanyTypes - The company types of the new item.
 * @param {boolean} isLibrary - Is this function called on common library data
 * @param {string} itemId - The id of the relevant item
 * @returns {Object} - An object containing the items referencing the item, items referenced by the item,
 * and a flag indicating if there is a company type conflict.
 */
export const doCompanyTypesConflict = (newVisibility, isLibrary, itemId) => {
  const { category, item } = getCategoryAndItemFromCache(isLibrary, itemId);

  const { itemsReferencingItem, itemsReferencedByItem } = getItemsInvolvedInFormula(item, category);

  const referencingItemsConflicting = haveReferencingItemsConflictingCompanyTypes(itemsReferencingItem, newVisibility);
  const referencedItemsConflicting = haveReferencedItemsConflictingCompanyTypes(itemsReferencedByItem, newVisibility);

  let companyTypeConflict = COMPANY_TYPE_CONFLICTS.NONE;
  if (referencingItemsConflicting && referencedItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCING_AND_REFERENCED_ITEMS_CONFLICT;
  } else if (referencingItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCING_ITEMS_CONFLICT;
  } else if (referencedItemsConflicting) {
    companyTypeConflict = COMPANY_TYPE_CONFLICTS.REFERENCED_ITEMS_CONFLICT;
  }

  return {
    itemsReferencingItem,
    itemsReferencedByItem,
    companyTypeConflict,
  };
};
