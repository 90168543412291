import Card from 'components/common/Card';
import equal from 'fast-deep-equal/es6/react';
import { TableMemo } from 'memo';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

/**
 * @typedef {Object} OverviewColumn
 * @property {string} title - the title of the column
 * @property {string} key - the key of the column
 * @property {ReactNode} render - the render function for the column
 * @property {string} dataIndex - the key of the data that is to be displayed in the column
 *
 * @param {Object} props - the props of the component
 * @param {string} props.t - the translate function
 * @returns {OverviewColumn[]} - an array of overview columns
 */
const overviewColumn = ({ t }) => [
  {
    title: t('viewer.ShoppingCartView.columns.data'),
    key: 'data',
    render: (data) => t(`viewer.ShoppingCartView.SepaCard.rows.${data}.label`),
    dataIndex: 'data',
  },
  {
    title: t('viewer.ShoppingCartView.columns.value'),
    key: 'value',
    dataIndex: 'value',
    render: (value, record) => {
      if (record.data === 'sepaInfo') {
        return (Object.entries(value) || []).map(([key, val]) => (
          <p key={key}>
            <Trans
              i18nKey={`viewer.ShoppingCartView.SepaCard.rows.${record.data}.values.${key}`}
              values={{ [key]: val ?? '-' }}
              components={{
                bold: <strong />,
              }}
            />
          </p>
        ));
      }
      return value ? t(`viewer.ShoppingCartView.SepaCard.rows.${record.data}.values.${value}`) : '-';
    },
  },
];

/**
 * Component for displaying SEPA information of a ShoppingCart.
 *
 * @param {Object} signatureData - Signature information of the ShoppingCart.
 * @param {optional | mandatory} signatureData.sepaMode - SEPA mode of the ShoppingCart.
 * @param {sepaAlpha | sepaBasis | sepaCompany} signatureData.sepaType - SEPA type of the ShoppingCart.
 * @param {object} signatureData.digitalSignatureActions - Digital signature actions of the ShoppingCart.
 * @returns {React.ReactElement} React component.
 */
const ShoppingCartSepaCard = ({ signatureData }) => {
  const { t } = useTranslation();

  const sepaMandateGranted = signatureData?.digitalSignatureActions?.find(
    (a) => a.actionType === 'SEPA_MANDATE_GRANTED',
  );

  const dataSource = [
    {
      data: 'sepaMode',
      value: signatureData?.sepaMode,
    },
    {
      data: 'sepaType',
      value: signatureData?.sepaType,
    },
    {
      data: 'sepaStatus',
      value: sepaMandateGranted ? 'granted' : 'notGranted',
    },
    {
      data: 'sepaInfo',
      value: {
        accountOwnerName: sepaMandateGranted?.additionalInfos?.accountOwnerName,
        iban: sepaMandateGranted?.additionalInfos?.iban,
        bic: sepaMandateGranted?.additionalInfos?.bic,
      },
    },
  ];

  return (
    <Card title={t('viewer.ShoppingCartView.SepaCard.title')}>
      <TableMemo
        rowKey={(record) => record?.data}
        dataSource={dataSource}
        columns={overviewColumn({ t })}
        pagination={false}
        scroll={{ x: 390 }}
      />
    </Card>
  );
};
export default memo(ShoppingCartSepaCard, equal);
