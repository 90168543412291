import { gql } from '@apollo/client';

const userDigitalSignaturePreferencesFragment = gql`
  fragment userDigitalSignaturePreferencesFragment on DigitalSignaturePreferences {
    automaticRemindSigneesAboutSignatureProcess
    automaticRemindSigneesAboutSignatureProcessInterval
    digitalSignatureEnabled
    notifyUsersByMail
    passwordEnabled
    autoSignForTenantSigneesEnabled
    defaultLinkValidityTime
    defaultTenantSignees
    notifyClientSigneesForDigitalSignatureStartTemplate
    notifyTenantSigneesForDigitalSignatureStartTemplate
    sendPasswordToSigneesTemplate
    noticeOfCancellationDocumentTemplate
    notifySigneesAboutSignedBySignee
    notifySigneesAboutSignedBySigneeTemplate
    notifySigneesForSuccessfulFinishTemplate
    notifySigneesForRevokeTemplate
    notifyTenantSigneesAboutOpenBySignee
    notifyTenantSigneesAboutOpenBySigneeTemplate
    remindSigneeAboutDigitalSignatureProcessTemplate
    postSuccessfulFinishState
    sepaDefaultTemplateTypeForCompany
    sepaDocumentTemplateBase
    sepaDocumentTemplateCompany
    sepaEnabled
    sepaIntroductionText
    sepaMandateCompanyText
    sepaMandatePrivateText
    sepaMode
  }
`;

export default userDigitalSignaturePreferencesFragment;
