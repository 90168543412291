import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import FormikForm from 'components/common/FormComponents/Formik/FormikForm';
import addNewClient from 'ProjectCardManagement/ClientManagement/methods/addNewClient';
import { QueryErrorAlert } from 'components/common/ErrorComponent';
import useClient from 'ProjectCardManagement/ClientManagement/hooks/useClient';
import editClient from 'ProjectCardManagement/ClientManagement/methods/editClient';
import { grabFirstGQLDataResult } from 'utils/helpers';

/**
 * Schema for new client form
 * @returns {Yup.ObjectSchema<object>}
 */
const ClientSchema = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t('ClientsListPage.addClientModal.form.name.required')),
    type: Yup.string().nullable().required(t('ClientsListPage.addClientModal.form.type.required')),
    legalForm: Yup.object().when('type', {
      is: (type) => type === 'company',
      then: Yup.object().shape({
        companyType: Yup.string().required(t('ClientsListPage.addClientModal.form.companyType.required')),
        annualReport: Yup.string().required(t('ClientsListPage.addClientModal.form.annualReport.required')),
        bookkeeping: Yup.string().required(t('ClientsListPage.addClientModal.form.bookkeeping.required')),
      }),
      otherwise: Yup.object().shape({
        companyType: Yup.string(),
        annualReport: Yup.string(),
        bookkeeping: Yup.string(),
      }),
    }),
  });

/**
 * Formik form component for adding a new client, which renders the fields got as childrens
 * @param {object} inputProperties - Input properties of the component
 * @param {React.RefObject} inputProperties.formikRef - Formik reference to access Formik's internal methods
 * @param {Function} inputProperties.setSubmitting - Function to toggle the submitting state of the form
 * @param {React.ReactNode} inputProperties.children - Children elements to render within the form
 * @param {React.RefObject} inputProperties.errorRef - Reference to the error message container for accessibility
 * @param {string | undefined} inputProperties.clientId - ID of the client to be edited; undefined for adding a new client
 * @param {boolean} inputProperties.forceValidation - Whether to force validation of the form on mount
 * @param {Function} inputProperties.onPreSubmit - Function to run before submitting the form
 * @param {Function} inputProperties.onPostSubmit - Function to run after submitting the form
 * @returns {JSX.Element} AddOrEditClientForm component
 * @component
 */
const AddOrEditClientForm = ({
  children,
  setSubmitting,
  formikRef,
  errorRef,
  clientId,
  forceValidation = false,
  onPreSubmit,
  onPostSubmit,
}) => {
  const { client } = useClient(clientId);
  const { t } = useTranslation();
  const [submitError, setSubmitError] = useState(null);

  const initialValues = useMemo(
    () => ({
      clientId: client?._id || null,
      name: client?.name || '',
      identifier: client?.identifier || '',
      type: client?.type || '',
      address: {
        country: client?.address?.country || '',
        city: client?.address?.city || '',
        cityCode: client?.address?.cityCode || '',
        street: client?.address?.street || '',
        houseNumber: client?.address?.houseNumber || '',
      },
      importFields: {
        commercialObject: client?.importFields?.commercialObject || '',
      },
      legalForm: {
        companyType: client?.legalForm?.companyType || '',
        annualReport: client?.legalForm?.annualReport || '',
        bookkeeping: client?.legalForm?.bookkeeping || '',
      },
    }),
    [
      client?._id,
      client?.name,
      client?.identifier,
      client?.type,
      client?.address?.country,
      client?.address?.city,
      client?.address?.cityCode,
      client?.address?.street,
      client?.address?.houseNumber,
      client?.importFields?.commercialObject,
      client?.legalForm?.companyType,
      client?.legalForm?.annualReport,
      client?.legalForm?.bookkeeping,
    ],
  );

  const clientSchema = useMemo(() => ClientSchema(t), [t]);

  const onSubmit = useCallback(
    async (values) => {
      const submitMethod = clientId ? editClient : addNewClient;

      if (onPreSubmit && !(await onPreSubmit(values))) return;

      try {
        const { data: newCompany } = await submitMethod(values);
        setSubmitting(false);
        if (onPostSubmit) await onPostSubmit(values, grabFirstGQLDataResult(newCompany));
      } catch (error) {
        setSubmitError(error);
        setSubmitting(false);
        throw error;
      }
    },
    [setSubmitting, clientId, onPreSubmit, onPostSubmit],
  );

  useEffect(() => {
    if (errorRef && !errorRef.current) {
      // eslint-disable-next-line no-param-reassign
      errorRef.current = { resetError: () => setSubmitError(null) };
    }
  }, [errorRef]);

  return (
    <FormikForm
      formikRef={formikRef}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={clientSchema}
      validateOnMount={forceValidation}
      enableReinitialize
    >
      <QueryErrorAlert error={submitError} />
      {children}
    </FormikForm>
  );
};

export default AddOrEditClientForm;
