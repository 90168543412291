import { useQuery } from '@apollo/client';
import { userCompanyQuery } from 'graphql/queries';

/**
 * Fetches client data from the server (Skips if no client ID is provided).
 * @param {string} clientId - The ID of the client
 * @param {string | null | undefined} fetchPolicy - The fetch policy to use for the query (Default: 'cache-and-network')
 * @returns {object} The client data, error, and loading state
 */
const useClient = (clientId, fetchPolicy = 'cache-and-network') => {
  const { data, error, loading } = useQuery(userCompanyQuery, {
    variables: { _id: clientId },
    fetchPolicy,
    skip: !clientId,
  });
  const client = data?.companyById;
  return { client, error, loading };
};

export default useClient;
