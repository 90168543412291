import { useCallback, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { userCompanyListQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { RoundedButton } from 'components/common/Button';
import confirmModal from 'utils/confirmModal';
import apollo from 'graphql/apollo';
import { userCompanyFragment } from 'graphql/fragments';
import Modal, { useModal } from 'components/common/Modal/Modal';
import AddButton from 'components/common/AddButton';
import FormikForm from 'components/common/FormComponents/Formik/FormikForm';
import AddOrEditClientModal from 'ProjectCardManagement/ClientManagement/components/AddOrEditClientModal';
import { confirmCompanyChangeModal, checkIfLegalFormMigrationIsRequired } from './utils';
import useHasSelectedItems from '../hooks/useHasSelectedItems';
import { CompanySelectSchema } from '../components/schema';
import { CompanyExistingFormikInput } from '../components/Inputs';

import classes from './CompanyCard.module.less';

/**
 * Component for the "Add Existing Company" tab.
 * @param {object} props - Component properties.
 * @param {function} props.openCreateClient - Callback function to change the current tab.
 * @returns {JSX.Element} A form input for selecting an existing company.
 * @component
 */
const SelectClientInput = ({ openCreateClient }) => {
  const { t } = useTranslation();
  const { data, loading } = useCachedQuery(userCompanyListQuery, { fetchPolicy: 'cache-and-network' });
  const companies = grabFirstGQLDataResult(data);
  return (
    <div>
      <CompanyExistingFormikInput
        changeTab={openCreateClient}
        companies={companies}
        loading={loading}
        placeholder={t('user.ShoppingCart.ContactData.company.inputs.company.placeholder')}
      />
    </div>
  );
};

/**
 * Component for rendering the modal content to handle company contacts.
 * @param {object} props - Component properties.
 * @param {function} props.onOk - Callback function executed when an option is selected.
 * @param {object} props.modal - Modal instance used to manage modal behavior (e.g., destruction).
 * @returns {JSX.Element} Buttons for managing company contacts within a modal.
 * @component
 */
const CheckForSwitchToDefaultContactsModalContent = ({ onOk, modal }) => {
  const handleOk = (isReplaceContacts) => {
    onOk(isReplaceContacts);
    modal.destroy();
  };
  const { t } = useTranslation();

  return (
    <div className="ant-modal-confirm-btns">
      <RoundedButton type="primary" onClick={() => handleOk(true)} size="large">
        {t('user.ShoppingCart.ContactData.modal.replace')}
      </RoundedButton>
      <RoundedButton onClick={() => handleOk(false)} size="large">
        {t('user.ShoppingCart.ContactData.modal.doNotReplace')}
      </RoundedButton>
      <RoundedButton onClick={modal.destroy} size="large">
        {t('user.ShoppingCart.ContactData.modal.cancel')}
      </RoundedButton>
    </div>
  );
};

/**
 * Function to open a confirmation modal for managing company contacts
 * @param {object} props - Properties of the modal
 * @param {function} props.onOk - Callback function executed after user interaction
 */
const checkForSwitchToDefaultContactsOfCompany = ({ onOk }) => {
  const modal = confirmModal({
    closable: true,
    maskClosable: true,
    width: 800,
    title: i18n.t('user.ShoppingCart.ContactData.modal.titles.changeCompanyContacts'),
    className: classes.confirmModal,
  });

  modal.update({
    content: <CheckForSwitchToDefaultContactsModalContent onOk={onOk} modal={modal} />,
  });
};

/**
 * Modal to select client for project card
 * @param {object} properties - Properties of the component
 * @param {boolean} properties.visible - Visibility of the modal
 * @param {object} properties.currentCompany - Current company
 * @param {function} properties.closeModal - Function to close the modal
 * @param {function} properties.onChange - Function to change the company
 * @param {array} properties.currentContacts - Current contacts
 * @param {function} properties.openMigrationModal - Function to open the migration modal
 * @returns {JSX.Element} SelectCompanyModal component
 * @component
 */
const SelectCompanyModal = ({ visible, currentCompany, closeModal, onChange, currentContacts, openMigrationModal }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [createNewClientModalVisible, showCreateNewClientModal, hideCreateNewClientModal] = useModal();
  const areItemsSelected = useHasSelectedItems();
  const formikRef = useRef();

  const initialValues = useMemo(
    () => ({
      company: currentCompany || null,
    }),
    [currentCompany],
  );

  const onOk = useCallback(async () => {
    if (!formikRef.current) {
      return;
    }
    try {
      await formikRef.current.submitForm();
      if (!formikRef.current.isValid) {
        setSubmitting(false);
        return;
      }
      // errorRef.current.resetError();
      formikRef.current.resetForm();
      closeModal();
    } catch (err) {
      // not empty
    }
  }, [closeModal]);

  const onCancel = useCallback(() => {
    if (submitting) return;
    if (formikRef.current) {
      // errorRef.current.resetError();
      formikRef.current.resetForm();
    }
    closeModal();
  }, [closeModal, submitting]);

  const onPostSubmit = useCallback(
    async (_, company) => {
      if (areItemsSelected) {
        try {
          await confirmCompanyChangeModal();
        } catch {
          return;
        }
      }

      if (currentContacts && currentContacts.length > 0 && currentCompany) {
        await checkForSwitchToDefaultContactsOfCompany({
          onOk: (isReplaceContacts) => {
            onChange(company, isReplaceContacts);
            closeModal();
          },
        });
      } else {
        onChange(company, true);
        closeModal();
      }
    },
    [areItemsSelected, currentCompany, currentContacts, onChange, closeModal],
  );

  const onSubmit = useCallback(
    async (values) => {
      const selectedCompany = apollo.readFragment({
        id: `Company___${values.company}`,
        fragmentName: 'userCompanyFragment',
        fragment: userCompanyFragment,
      });

      if (areItemsSelected) {
        try {
          await confirmCompanyChangeModal();
        } catch {
          return;
        }
      }

      if (currentCompany && currentCompany === selectedCompany._id) {
        closeModal();
        return;
      }

      const areContactsEqual = isEqual(selectedCompany.contacts, currentContacts);

      closeModal();

      if (!areContactsEqual && selectedCompany.contacts.length > 0 && currentCompany) {
        await checkForSwitchToDefaultContactsOfCompany({
          onOk: (isReplaceContacts) => {
            onChange(selectedCompany, isReplaceContacts);
          },
        });
      } else if (!areContactsEqual) {
        onChange(selectedCompany, true);
      } else {
        onChange(selectedCompany, false);
      }

      if (checkIfLegalFormMigrationIsRequired(selectedCompany)) {
        openMigrationModal();
      }
    },
    [areItemsSelected, currentCompany, currentContacts, onChange, closeModal, openMigrationModal],
  );

  return (
    <Modal
      size="medium"
      visible={visible}
      title={t('user.ShoppingCart.ContactData.modal.titles.changeCompany')}
      okText={t('user.ShoppingCart.ContactData.modal.ok')}
      cancelText={t('user.ShoppingCart.ContactData.modal.cancel')}
      onOk={onOk}
      onCancel={onCancel}
      confirmLoading={submitting}
    >
      <FormikForm
        formikRef={formikRef}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={CompanySelectSchema()}
        enableReinitialize
      >
        <SelectClientInput />
      </FormikForm>
      <AddOrEditClientModal
        visible={createNewClientModalVisible}
        closeModal={hideCreateNewClientModal}
        onPostSubmit={onPostSubmit}
      />
      <AddButton text={t('user.ShoppingCart.ContactData.modal.addClient')} onClick={showCreateNewClientModal} />
    </Modal>
  );
};

export default SelectCompanyModal;
