import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { useTranslation } from 'react-i18next';
import { Select } from 'formik-antd';

/**
 * Renders a dropdown (select) component for choosing a bookkeeping option.
 * @component
 * @returns {JSX.Element} The rendered select component for bookkeeping options.
 */
const ClientBookkeepingSelect = () => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('ClientsListPage.addClientModal.form.bookkeeping.options.selfWithConsultant'),
      value: 'SELF_WITH_CONSULTANT',
    },
    {
      label: t('ClientsListPage.addClientModal.form.bookkeeping.options.selfWithoutConsultant'),
      value: 'SELF_WITHOUT_CONSULTANT',
    },
    {
      label: t('ClientsListPage.addClientModal.form.bookkeeping.options.taxConsultant'),
      value: 'TAX_CONSULTANT',
    },
  ];

  return (
    <FormItem label={t('ClientsListPage.addClientModal.form.bookkeeping.label')} name="legalForm.bookkeeping">
      <Select
        name="legalForm.bookkeeping"
        options={options}
        placeholder={t('ClientsListPage.addClientModal.form.bookkeeping.placeholder')}
      />
    </FormItem>
  );
};

export default ClientBookkeepingSelect;
