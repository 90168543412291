import { ApolloProvider } from '@apollo/client';
import { confirmAlert } from '@uplab/react-confirm-alert';
import { Button } from 'antd';
import { setGraphqlErrors } from 'components/common/ErrorComponent';
import { Input } from 'formik-antd';
import { useFunctionToRefCB } from 'memo';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import apolloClient from 'graphql/apollo';
import { useThemeRichEditor } from 'graphql/hooks';
import { ThemeProvider } from 'styled-components';
import InputConfirmModal from './InputConfirmModalComponent';

const CustomFooter =
  ({ okText, okIcon, cancelText }) =>
  ({ onClose, onSubmit }) =>
    (
      <div style={{ float: 'right' }}>
        <Button onClick={onClose}>{cancelText}</Button>{' '}
        <Button icon={okIcon} onClick={onSubmit} type="primary">
          {okText}
        </Button>
      </div>
    );
const InputConfirmModalContainer = ({
  bodyText,
  footer: footerProps,
  errorResolver,
  fields,
  formContent,
  forceMultiField,
  headerText,
  okText,
  okIcon,
  cancelText,
  // notRenderFormik,
  onClose: _onClose,
  unmount,
  onCloseAfter,
  onReject,
  onSubmitWithClose,
  onSubmit,
  validationSchema,
  value,
  width,
  onChange,
  forceValidate,
  ...props
  // formikContext,
}) => {
  const { theme } = useThemeRichEditor();
  const [isSubmitting, setIsSubmitting] = useState();
  const [isClosing, setIsClosing] = useState(false);
  const formikRef = useRef();
  useEffect(() => {
    if (forceValidate && formikRef.current) {
      formikRef.current.submitForm();
    }
  }, [forceValidate]);
  const validationSchema_ = useCallback((field, values) => validationSchema({ formikRef, values }), [validationSchema]);
  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.persist(); // fix for not reusing this SyntheticEvent.
    onReject && event.clickedOnCancelButton && onReject();
    setIsClosing(true);
  };
  const onSubmit_ = async (values, formik) => {
    setIsSubmitting(true);
    if (isSubmitting) return;
    try {
      const formikValues = fields.length === 1 && !forceMultiField ? Object.values(values)[0] : values;
      if (typeof onSubmitWithClose === 'function') {
        await onSubmitWithClose({ values: formikValues, close: () => setIsClosing(true) });
      } else {
        await onSubmit(formikValues);
        setIsClosing(true);
      }
    } catch (e) {
      formik.setErrors(
        setGraphqlErrors({ error: e, errorResolver, formik, showErrorsInFormForValues: Object.keys(value) }),
      );
    }
    setIsSubmitting(false);
  };
  const getFormik = useFunctionToRefCB(() => formikRef.current);
  const onClose = useCallback(
    async (e) => {
      const closeCancelled = _onClose
        ? (await _onClose(e, {
            formik: getFormik(),
          })) === false
        : false;
      if (closeCancelled) return;
      onCloseAfter(getFormik());
      setIsClosing(true);
      setTimeout(() => unmount(), 300 /* animation timeout */);
    },
    [_onClose, getFormik, onCloseAfter, unmount],
  );
  const footer = useMemo(() => {
    if (footerProps)
      return typeof footerProps === 'boolean' ? CustomFooter({ cancelText, okText, okIcon }) : footerProps;
    return null;
  }, [cancelText, footerProps, okIcon, okText]);
  return (
    <ThemeProvider theme={theme}>
      <InputConfirmModal
        {...props}
        bodyText={bodyText}
        closeModal={onClose}
        onClose={onClose}
        errorResolver={errorResolver}
        fields={fields}
        formContent={formContent}
        forceMultiField={forceMultiField}
        handleClose={handleClose}
        headerText={headerText}
        isClosing={isClosing}
        isSubmitting={isSubmitting}
        okText={okText}
        cancelText={cancelText}
        // notRenderFormik={notRenderFormik}
        onSubmit={onSubmit_}
        ref={formikRef}
        validationSchema={validationSchema_}
        value={value}
        // formikContext={formikContext}
        width={width}
        onChange={onChange}
        footer={footer}
      />
    </ThemeProvider>
  );
};
export const defaultSchema =
  (fields) =>
  ({ formikRef }) =>
    Yup.object().shape({
      ...fields.reduce(
        (prev, field) => ({
          ...prev,
          ...(field.shouldRenderFn && !field.shouldRenderFn(formikRef.current)
            ? {}
            : {
                [field.name]:
                  field?.validationSchema?.({ formikRef }) ||
                  Yup.string().label(field.description).required().min(1).nullable(),
              }),
        }),
        {},
      ),
    });

const emptyFunc = () => {};
const getField = (object) => ({
  component: Input,
  description: 'New field',
  label: 'New',
  name: 'input',
  ...object,
});
export const inputConfirmModal = ({
  bodyText = '',
  errorResolver,
  fields,
  formContent,
  forceMultiField,
  headerText = 'New',
  okText,
  cancelText,
  // notRenderFormik,
  onReject = emptyFunc,
  onSubmitWithClose,
  onSubmit = emptyFunc,
  validationSchema = defaultSchema(fields),
  value = fields.length === 1 && !forceMultiField ? '' : {},
  width,
  onChange,
  onClose: _onClose = emptyFunc,
  onCloseAfter = emptyFunc,
  ...props
  // formikContext,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <ApolloProvider client={apolloClient}>
        <InputConfirmModalContainer
          {...props}
          bodyText={bodyText}
          errorResolver={errorResolver}
          fields={fields.map((f) => getField(f))}
          formContent={formContent}
          forceMultiField={forceMultiField}
          headerText={headerText}
          // notRenderFormik={notRenderFormik}
          unmount={onClose}
          onClose={_onClose}
          onSubmitWithClose={onSubmitWithClose}
          onCloseAfter={onCloseAfter}
          okText={okText}
          cancelText={cancelText}
          onReject={onReject}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          value={value}
          width={width}
          onChange={onChange}
          // formikContext={formikContext}
        />
      </ApolloProvider>
    ),
  });
};
export const singleInputConfirmModal = ({
  bodyText,
  okText,
  cancelText,
  fieldDescription,
  fieldLabel,
  errorResolver,
  headerText,
  onReject,
  onSubmit,
  validationSchema,
  value,
  width,
  ...props
  // notRenderFormik,
}) =>
  inputConfirmModal({
    ...props,
    bodyText,
    okText,
    cancelText,
    errorResolver,
    headerText,
    onReject,
    onSubmit,
    validationSchema,
    value,
    width,
    // notRenderFormik,
    fields: [
      getField({
        label: fieldLabel,
        description: fieldDescription,
        name: 'input',
      }),
    ],
  });

export default inputConfirmModal;
