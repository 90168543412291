/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { Input, Select as SelectFormik } from 'formik-antd';
import { Button, Select, Alert } from 'antd';
import { useFormikContext } from 'formik';
import { useCountries } from 'utils/locale';
import { useCachedQuery } from 'graphql/utils';
import { userContactListQuery } from 'graphql/queries';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { filter } from 'lodash';
import 'antd/dist/antd.css';
import { useFormikField } from 'hooks/common/useFormikField';
import i18n from 'i18n';
import PhoneNumber from 'components/common/PhoneInputFormik';
import salutations from 'constants/salutations';
import equal from 'fast-deep-equal/es6/react';
import { memo, useMemo } from 'react';
import classes from './Inputs.module.less';

// const defaultAntdFilterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
const defaultAntdFilterOption = (input, option) =>
  input
    .toLowerCase()
    .split(' ')
    .map((word) => option.children.toLowerCase().indexOf(word) >= 0)
    .every(Boolean);
const filterByContent = (input, option) =>
  input
    .toLowerCase()
    .split(' ')
    .map((word) => option.children.toLowerCase().indexOf(word) >= 0)
    .every(Boolean);

export const ContactForm = ({ changeTab, isAccountForm, isUpdate }) => {
  const typeContactData = 'contact';
  const { t } = useTranslation();
  const { setFieldValueAndTouched, values, validateForm } = useFormikContext();
  const { data } = useCachedQuery(userContactListQuery, { fetchPolicy: 'cache-first' });
  const allContacts = grabFirstGQLDataResult(data);
  const duplicatedContact = useMemo(
    () =>
      values.email
        ? filter(allContacts || [], (contact) => contact.email === values.email && contact._id !== values._id)
        : [],
    [allContacts, values._id, values.email],
  );
  return (
    <>
      <div className={classes.group}>
        <SalutationFormikInput typeContactData={typeContactData} />
        <FirstNameFormikInput containerProps={{ className: 'flex-1' }} typeContactData={typeContactData} />
        <LastNameFormikInput containerProps={{ className: 'flex-1' }} typeContactData={typeContactData} />
      </div>
      <FormItem name="fullSalutation" label={t(`user.ShoppingCart.ContactData.contact.inputs.fullSalutation.label`)}>
        <Input
          name="fullSalutation"
          placeholder={t(`user.ShoppingCart.ContactData.contact.inputs.fullSalutation.placeholder`)}
        />
      </FormItem>
      <PositionFormikInput typeContactData={typeContactData} />
      {isAccountForm ? null : (
        <>
          <EmailFormikInput typeContactData={typeContactData} />
          {duplicatedContact.length ? (
            <Alert
              showIcon
              message={
                <>
                  {t(
                    `user.ShoppingCart.ContactData.contact.duplicatedContactAlert.${
                      isUpdate ? 'editMessage' : 'message'
                    }`,
                  )}
                  {changeTab && !isUpdate ? (
                    <Button
                      size="small"
                      type="link"
                      className={classes.emailAlertActionButton}
                      onClick={async () => {
                        await changeTab('1');
                        await setFieldValueAndTouched('contact', duplicatedContact[0]._id);
                        validateForm();
                      }}
                    >
                      {t('user.ShoppingCart.ContactData.contact.duplicatedContactAlert.useExistingContactButton')}
                    </Button>
                  ) : null}
                </>
              }
              type="info"
              className="alert-info"
            />
          ) : null}
        </>
      )}
    </>
  );
};
export const CompanyNameFormikInput = () => {
  const name = 'name';
  const { t } = useTranslation();
  const { value: type } = useFormikField('type');

  return (
    <FormItem name={name} label={t(`user.ShoppingCart.ContactData.company.inputs.${name}.${type}.label`)}>
      <Input
        name={name}
        placeholder={t(`user.ShoppingCart.ContactData.company.inputs.${name}.${type}.placeholder`)}
        autoComplete="off"
      />
    </FormItem>
  );
};
export const CityCodeFormikInput = ({ containerProps }) => <InputFormik name="cityCode" containerProps={containerProps} />; // prettier-ignore
export const CityFormikInput = ({ containerProps }) => <InputFormik name="city" containerProps={containerProps} />;
export const CompanyIdentifierFormikInput = () => <InputFormik name="identifier" autoComplete="off" />;
export const CompanyCommercialObject = () => <InputFormik name="commercialObject" autoComplete="off" />;
export const EmailFormikInput = ({ typeContactData }) => (
  <InputFormik name="email" typeContactData={typeContactData} autoComplete="off" />
);
export const PositionFormikInput = ({ typeContactData }) => <InputFormik name="position" typeContactData={typeContactData} />; // prettier-ignore
export const FirstNameFormikInput = ({ typeContactData, containerProps }) => <InputFormik name="firstName" typeContactData={typeContactData} containerProps={containerProps} autoComplete="off"/>; // prettier-ignore
export const HouseNumberFormikInput = ({ containerProps }) => <InputFormik name="houseNumber" containerProps={containerProps} autoComplete="off"/>; // prettier-ignore
export const LastNameFormikInput = ({ typeContactData, containerProps }) => <InputFormik name="lastName" typeContactData={typeContactData} containerProps={containerProps} autoComplete="off"/>; // prettier-ignore
export const StreetFormikInput = ({ containerProps }) => (
  <InputFormik name="street" containerProps={containerProps} autoComplete="off" />
);
export const NumberPhoneAlternativeFormikInput = ({ typeContactData }) => <InputFormik name="phoneNumberAlternative" Component={PhoneNumber} typeContactData={typeContactData} autoComplete="off"/>; // prettier-ignore
export const NumberPhoneBusinessFormikInput = ({ typeContactData }) => <InputFormik name="phoneNumber" Component={PhoneNumber} typeContactData={typeContactData} autoComplete="off"/>; // prettier-ignore

function InputFormik({ name, containerProps, Component = Input, typeContactData = 'company', componentProps }) {
  const { t } = useTranslation();
  return (
    <FormItem
      name={name}
      label={t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.${name}.label`)}
      containerProps={containerProps}
    >
      <Component
        name={name}
        placeholder={t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.${name}.placeholder`)}
        autoComplete="off"
        {...componentProps}
      />
    </FormItem>
  );
}
export default InputFormik;

export function SalutationFormikInput({ typeContactData }) {
  const { t } = useTranslation();
  const mapSalutations = Object.values(salutations)
    .map((e) => ({
      _id: e,
      label: e ? t(`user.ShoppingCart.ContactData.contact.inputs.salutation.options.${e}`) : '',
    }))
    .filter(({ _id }) => !(t('localeCode') === 'de' && _id === 'Mrs'));
  return (
    <InputFormik
      name="salutation"
      autoComplete="off"
      typeContactData={typeContactData}
      Component={FieldSearchDropdown}
      componentProps={{
        options: mapSalutations,
        placeholder: t(`user.ShoppingCart.ContactData.${typeContactData}.inputs.salutation.placeholder`),
        allowClear: true,
      }}
    />
  );
}

export function CountryFormikDropdownInput() {
  const countries = useCountries();
  const { t } = useTranslation();

  return (
    <InputFormik
      name="country"
      Component={FieldSearchDropdown}
      componentProps={{
        filterOption: filterByContent,
        options: countries,
        notFoundContent: () => t('user.ShoppingCart.ContactData.company.noCountries'),
        placeholder: t('user.ShoppingCart.ContactData.company.inputs.country.placeholder'),
      }}
    />
  );
}

const NotFoundContent = memo(
  ({ noFoundText, createText, changeTab }) => (
    <div className={classes.emptyOption}>
      <div>{noFoundText}</div>
      <Button type="primary" onClick={() => changeTab('2')}>
        {createText}
      </Button>
    </div>
  ),
  equal,
);

export const contactToString = (contact) => (contact ? `${contact.lastName} ${contact.firstName}` : '');
export const companyToString = (company) =>
  company
    ? `${company.name} (${i18n.t(`user.ShoppingCart.ContactData.company.inputs.type.${company.type?.toLowerCase()}`)})`
    : i18n.t('user.ShoppingCart.ContactData.company.inputs.company.label');

export function ContactExistingFormikInput({ contacts = [], loading, changeTab }) {
  const { t } = useTranslation();
  const name = 'contact';
  return (
    <InputFormik
      name={name}
      typeContactData={name}
      Component={FieldSearchDropdown}
      componentProps={{
        loading,
        // eslint-disable-next-line react/no-unstable-nested-components
        notFoundContent: () => (
          <NotFoundContent
            changeTab={changeTab}
            noFoundText={t('user.ShoppingCart.ContactData.contact.notFoundContent.noFound')}
            createText={t('user.ShoppingCart.ContactData.contact.notFoundContent.create')}
          />
        ),
        itemRender: (props) => contactToString(props),
        options: contacts,
        filterOption: filterByContent,
        placeholder: t(`user.ShoppingCart.ContactData.contact.inputs.${name}.placeholder`),
      }}
    />
  );
}

export function CompanyExistingFormikInput({ companies = [], loading, changeTab, placeholder }) {
  const { t } = useTranslation();
  return (
    <InputFormik
      name="company"
      typeContactData="company"
      Component={FieldSearchDropdown}
      componentProps={{
        loading,
        filterOption: filterByContent,
        // eslint-disable-next-line react/no-unstable-nested-components
        notFoundContent: () => (
          <NotFoundContent
            changeTab={changeTab}
            noFoundText={t('user.ShoppingCart.ContactData.company.notFoundContent.noFound')}
            createText={t('user.ShoppingCart.ContactData.company.notFoundContent.create')}
          />
        ),
        itemRender: (props) => companyToString(props),
        options: companies,
        placeholder,
      }}
    />
  );
}

export function FieldSearchDropdown({
  allowClear,
  filterOption,
  itemRender = (e) => e?.label,
  loading = false,
  name,
  notFoundContent: NotFoundContent,
  options,
  placeholder,
  ...props
}) {
  const S = name ? SelectFormik : Select;
  return (
    <S
      name={name}
      loading={loading}
      notFoundContent={NotFoundContent ? <NotFoundContent /> : null}
      showSearch
      placeholder={placeholder}
      filterOption={filterOption || defaultAntdFilterOption}
      allowClear={allowClear}
      {...props}
    >
      {options?.map((e) => (
        <S.Option value={e._id} key={e._id}>
          {itemRender(e)}
        </S.Option>
      ))}
    </S>
  );
}
