import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/common/Modal/Modal';
import AddOrEditClientForm from './AddClientModal/AddOrEditClientForm';
import ClientNumberField from './AddClientModal/ClientNumberField';
import ClientTypeSelect from './AddClientModal/ClientTypeSelect';
import ClientNameField from './AddClientModal/ClientNameField';
import CompanyFields from './AddClientModal/CompanyFields';
import ClientCommericalObject from './AddClientModal/ClientCommercialObjectField';
import ClientAddressFields from './AddClientModal/ClientAddressFields';
import LegalFormFields from './AddClientModal/LegalFormFields';
import classes from './AddClientModal.module.less';
import MigrationAlert from './AddClientModal/MigrationAlert';

/**
 * Renders the form fields for the client form, including fields for client number, type,
 * name, company-specific details, and address.
 * @returns {JSX.Element} The rendered client form fields.
 * @component
 */
export const ClientFormFields = () => {
  return (
    <>
      <ClientNumberField />
      <ClientTypeSelect />
      <ClientNameField />
      <CompanyFields>
        <LegalFormFields />
        <ClientCommericalObject />
      </CompanyFields>
      <ClientAddressFields />
    </>
  );
};

/**
 * Modal with form for adding or editing a new client to a tenant instance
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.visible - Visibility of the modal
 * @param {Function} inputProperties.closeModal - Function to close the modal
 * @param {string} inputProperties.clientId - ID of the client to be edited; undefined for adding a new client
 * @param {boolean} inputProperties.showMigrationNotice - Whether to show a migration notice in the modal
 * @param {boolean} inputProperties.forceValidation - Whether to force validation of the form on mount
 * @param {Function} inputProperties.onPreSubmit - Function to run before submitting the form
 * @param {Function} inputProperties.onPostSubmit - Function to run after submitting the form
 * @returns {JSX.Element} Add client modal
 * @component
 */
const AddOrEditClientModal = ({
  visible,
  closeModal,
  clientId,
  showMigrationNotice = false,
  forceValidation,
  onPreSubmit,
  onPostSubmit,
}) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const formikRef = useRef();
  const errorRef = useRef();

  return (
    <Modal
      visible={visible}
      className={classes.Modal}
      title={clientId ? t('ClientsListPage.addClientModal.editTitle') : t('ClientsListPage.addClientModal.title')}
      okText={t('ClientsListPage.addClientModal.okButton')}
      onCancel={() => {
        if (submitting) return;
        if (formikRef.current) {
          errorRef.current.resetError();
          formikRef.current.resetForm();
        }
        closeModal();
      }}
      onOk={async () => {
        if (!formikRef.current) {
          return;
        }
        setSubmitting(true);
        try {
          await formikRef.current.submitForm();
          if (!formikRef.current.isValid) {
            setSubmitting(false);
            return;
          }
          errorRef.current.resetError();
          formikRef.current.resetForm();
          closeModal();
        } catch (err) {
          // not empty
        }
      }}
      confirmLoading={submitting}
    >
      <AddOrEditClientForm
        formikRef={formikRef}
        setSubmitting={setSubmitting}
        errorRef={errorRef}
        clientId={clientId}
        onPreSubmit={onPreSubmit}
        onPostSubmit={onPostSubmit}
        forceValidation={forceValidation}
      >
        {showMigrationNotice ? <MigrationAlert /> : null}
        <ClientFormFields />
      </AddOrEditClientForm>
    </Modal>
  );
};

export default AddOrEditClientModal;
