import { useMemo, useEffect, useCallback, useRef } from 'react';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router';
import { FaPlus, FaBuilding } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { CompanyTypeUtility } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useQuery } from '@apollo/client';
import apollo from 'graphql/apollo';
import { userCompanyFragment } from 'graphql/fragments';
import confirmModal from 'utils/confirmModal';
import { getAnnualReportLabel, grabFirstGQLDataResult } from 'utils/helpers';
import { userCompanyQuery } from 'graphql/queries';
import GraphQLLoadingWrapper from 'components/common/GraphQLLoadingWrapper';
import getCountryName from 'ProjectCardManagement/ClientManagement/viewModel/getCountryName';
import { useModal } from 'components/common/Modal/Modal';
import { useCategoriesContext } from 'components/user/shoppingCart/context';
import AddOrEditClientModal from 'ProjectCardManagement/ClientManagement/components/AddOrEditClientModal';
import { VATType } from 'constants/shoppingCart';
import { companyToString } from '../components/Inputs';
import EditButton from '../components/EditButton';
import SwitchCompanyButton from '../components/SwitchCompanyButton';
import { confirmCompanyChangeModal, checkIfLegalFormMigrationIsRequired } from './utils';
import useHasSelectedItems from '../hooks/useHasSelectedItems';
import SelectCompanyModal from './SelectClientModal';
import { deselectItems } from '../../utils';

import classes from './CompanyCard.module.less';

/**
 * Displays detailed information about a company's legal form.
 * @param {object} props - The component's properties.
 * @param {object} props.company - The company data object.
 * @param {string} props.company.type - The type of the entity ('company' or 'individual').
 * @param {object} props.company.legalForm - The legal form details of the company.
 * @param {string} props.company.legalForm.companyTypeName - The name of the company type.
 * @param {string} props.company.legalForm.annualReport - The annual report information of the company.
 * @param {string} props.company.legalForm.country - The country associated with the legal form.
 * @param {string} props.company.legalForm.bookkeeping - The bookkeeping information of the company.
 * @returns {JSX.Element|null} A JSX element displaying the legal form details or null if the company type is not 'company'.
 * @component
 */
const LegalFormDetails = ({ company }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ClientViewPage.clientDetails' });
  const instance = useMemo(() => new CompanyTypeUtility(), []);

  if (company.type !== 'company' || checkIfLegalFormMigrationIsRequired(company)) return null;

  return (
    <>
      <br />
      <i>
        <div>
          <span>
            {company.legalForm.companyTypeName}{' '}
            <span className={classes.abbreviation}>{instance.getAbbreviation(company.legalForm.companyTypeName)}</span>
          </span>
        </div>
        <div>
          <span>
            {t(`annualReport.${getAnnualReportLabel(company.legalForm.annualReport, company.legalForm.country)}`)}
          </span>
        </div>
        <div>
          <span>{t(`bookkeeping.${company.legalForm.bookkeeping}`)}</span>
        </div>
      </i>
    </>
  );
};

/**
 * Component that displays the address details of a company.
 * @param {object} company - The company object containing address details.
 * @param {object} company.address - The address object of the company.
 * @param {string} company.address.street - The street name of the company's address.
 * @param {string} company.address.houseNumber - The house number of the company's address.
 * @param {string} company.address.cityCode - The postal or city code of the company's address.
 * @param {string} company.address.city - The city of the company's address.
 * @param {string} company.address.country - The country code of the company's address.
 * @returns {JSX.Element} A JSX element that renders the company's address.
 * @component
 */
const AddressDetails = ({ company }) => {
  return (
    <>
      <div>
        <span>{company.address.street}</span> <span>{company.address.houseNumber}</span>
      </div>
      <div>
        <span>{company.address.cityCode}</span> <span>{company.address.city}</span>
      </div>
      <div>
        <span>{getCountryName(company.address.country)}</span>
      </div>
    </>
  );
};

/**
 * Updates project card properties based on new client values like vat settings and shown items and categories
 * @param {object} newValue - new client values
 * @param {func} setFieldValueAndTouched - function to set field value and touched
 * @param {object} formValues - form values
 * @param {object} categoriesOfConfiguration - categories of configuration
 * @param {boolean} hasSelectedItems - Are items selected in current project card
 */
export const changeFieldsBasedOnCompany = (
  newValue,
  setFieldValueAndTouched,
  formValues,
  categoriesOfConfiguration,
  hasSelectedItems = true,
) => {
  const isPrivatePerson = newValue.type === 'individual';
  setFieldValueAndTouched('showVat', isPrivatePerson);
  setFieldValueAndTouched('vatType', isPrivatePerson ? VATType.PERSONAL : VATType.COMPANY);
  if (hasSelectedItems) deselectItems(categoriesOfConfiguration, formValues, setFieldValueAndTouched, newValue);
};

/**
 * Component that displays the details of a company, including address and legal form details.
 * @param {object} company - The company object containing the company details.
 * @param {object} company.address - The address details of the company.
 * @param {string} company.address.street - The street of the company's address.
 * @param {string} company.address.houseNumber - The house number of the company's address.
 * @param {string} company.address.cityCode - The postal code of the company's address.
 * @param {string} company.address.city - The city of the company's address.
 * @param {string} company.address.country - The country code of the company's address.
 * @param {function} showModal - Function to show the modal for switching the company.
 * @param {function} onChange - Callback function to handle the updated company data after modification.
 * @returns {JSX.Element} A JSX element that renders the company's details and actions (edit and switch).
 * @component
 */
const CompanyDetails = ({ company, showModal, onChange }) => {
  const selectedItems = useHasSelectedItems();
  const categories = useCategoriesContext();
  const { setFieldValueAndTouched, values: formValues } = useFormikContext();
  const [editClientModalVisible, showEditClientModal, hideEditClientModal] = useModal();

  const onPreSubmit = useCallback(async () => {
    if (selectedItems) {
      try {
        await confirmCompanyChangeModal();
        return true;
      } catch (error) {
        return false;
      }
    }
    return true;
  }, [selectedItems]);

  const onPostSubmit = useCallback(
    async (modifier, submittedCompany) => {
      onChange(submittedCompany);
      changeFieldsBasedOnCompany(modifier, setFieldValueAndTouched, formValues, categories, selectedItems);
    },
    [onChange, selectedItems, setFieldValueAndTouched, formValues, categories],
  );

  return (
    <>
      <div className={classes.companyCartContent}>
        <AddressDetails company={company} />
        <LegalFormDetails company={company} />
      </div>
      <div className={classes.cardCompanyActions}>
        <EditButton onEdit={showEditClientModal} />
        <SwitchCompanyButton onClick={showModal} />
        <AddOrEditClientModal
          clientId={company._id}
          closeModal={hideEditClientModal}
          visible={editClientModalVisible}
          onPreSubmit={onPreSubmit}
          onPostSubmit={onPostSubmit}
        />
      </div>
    </>
  );
};

/**
 * Component that displays a message indicating that the company details are empty.
 * @returns {JSX.Element} A JSX element that renders a building icon and a translated empty message.
 * @component
 */
const EmptyCompanyDetails = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.empty}>
      <div>
        <FaBuilding size="50px" />
      </div>
      <div>{t('user.ShoppingCart.ContactData.company.empty')}</div>
    </div>
  );
};

/**
 * Card component that displays an empty company card with a button to show the select client modal.
 * @param {object} properties - Properties of the component
 * @param {object} properties.company - Company object
 * @param {function} properties.showModal - Function to show the select client modal
 * @returns {JSX.Element} Empty company card
 * @component
 */
const EmptyCompanyCard = ({ company, showModal }) => {
  return (
    <Card
      onClick={showModal}
      title={companyToString(company)}
      className={cn(classes.cardCompany, 'cursor-pointer')}
      extra={<Button className="ant-button-transparent" icon={<FaPlus />} />}
    >
      <EmptyCompanyDetails />
    </Card>
  );
};

/**
 * Card component that displays a company card with details and an option to show a modal for selecting a company and editing the company data
 * @param {object} properties - Properties of the component
 * @param {object} properties.company - Company object
 * @param {function} properties.showModal - Function to show the select client modal
 * @param {function} properties.onChange - Callback function to handle changes in the company data
 * @returns {JSX.Element} Company card with selected company
 * @component
 */
const CompanyCardWithSelectedCompany = ({ company, showModal, onChange }) => {
  return (
    <Card
      title={
        <div className={classes.title}>
          <h3>{companyToString(company)}</h3>
          {company?.identifier ? <div>{company.identifier}</div> : null}
        </div>
      }
      className={cn(classes.cardCompany)}
    >
      <CompanyDetails company={company} showModal={showModal} onChange={onChange} />
    </Card>
  );
};

/**
 * Component that displays a company's card with details and an option to show a modal for adding a company.
 * @param {string} companyId - The unique identifier of the company to fetch data for.
 * @param {function} showModal - Function to show the modal for adding or switching a company.
 * @param {function} onChange - Callback function to handle changes in the company details.
 * @param {string} initializationConfigDateForCompany - Initialization configuration date to be passed as a variable in the query.
 * @returns {JSX.Element} A JSX element that renders the company card, or a placeholder if no company is found.
 * @component
 */
const CompanyCard = ({ companyId, onChange, initializationConfigDateForCompany, contacts }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categories = useCategoriesContext();
  const [editClientModalVisible, showEditClientModal, hideEditClientModal] = useModal();
  const [selectClientModalVisible, showSelectClientModal, hideSelectClientModal] = useModal();
  const { setFieldValueAndTouched, values: formValues } = useFormikContext();
  const hasOpenedForceMigrateModal = useRef(false);

  const { data, ...rest } = useQuery(userCompanyQuery, {
    variables: { _id: companyId, initializationConfigDate: initializationConfigDateForCompany },
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  });

  const company = useMemo(() => grabFirstGQLDataResult(data), [data]);

  const onPostSubmitMigrate = useCallback(
    async (modifier, newCompany) => {
      onChange(newCompany);
      changeFieldsBasedOnCompany(modifier, setFieldValueAndTouched, formValues, categories);
      if (checkIfLegalFormMigrationIsRequired(newCompany)) navigate(`/secure/client/${companyId}`);
    },
    [onChange, setFieldValueAndTouched, companyId, navigate, formValues, categories],
  );

  const onCloseMigrationModal = useCallback(() => {
    const selectedCompany = apollo.readFragment({
      id: `Company___${companyId}`,
      fragmentName: 'userCompanyFragment',
      fragment: userCompanyFragment,
    });
    if (!selectedCompany || !checkIfLegalFormMigrationIsRequired(selectedCompany)) {
      hideEditClientModal();
      return;
    }
    confirmModal({
      title: t('ClientsListPage.addClientModal.migrationModal.confirmCloseWithoutMigration.title'),
      content: t('ClientsListPage.addClientModal.migrationModal.confirmCloseWithoutMigration.content'),
      onOk: () => {
        hideEditClientModal();
        navigate(`/secure/client/${companyId}`);
      },
    });
  }, [companyId, hideEditClientModal, t, navigate]);

  useEffect(() => {
    if (company && checkIfLegalFormMigrationIsRequired(company) && !hasOpenedForceMigrateModal.current) {
      hasOpenedForceMigrateModal.current = true;
      showEditClientModal();
    }
  }, [company, showEditClientModal]);

  return (
    <GraphQLLoadingWrapper data={data} {...rest} isAlwaysDisplay>
      {company ? (
        <CompanyCardWithSelectedCompany company={company} showModal={showSelectClientModal} onChange={onChange} />
      ) : (
        <EmptyCompanyCard company={company} showModal={showSelectClientModal} />
      )}
      <AddOrEditClientModal
        clientId={companyId}
        closeModal={onCloseMigrationModal}
        visible={editClientModalVisible}
        onPostSubmit={onPostSubmitMigrate}
        showMigrationNotice
        forceValidation
      />
      <SelectCompanyModal
        visible={selectClientModalVisible}
        currentCompany={company?._id}
        closeModal={hideSelectClientModal}
        onChange={(newSelectedCompany, isReplaceContacts) => {
          onChange(newSelectedCompany, isReplaceContacts);
          changeFieldsBasedOnCompany(newSelectedCompany, setFieldValueAndTouched, formValues, categories);
        }}
        currentContacts={contacts}
        openMigrationModal={showEditClientModal}
      />
    </GraphQLLoadingWrapper>
  );
};
export default CompanyCard;
