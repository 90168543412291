import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'formik-antd';
import { useFormikField } from 'hooks/common/useFormikField';
import classes from './BookkeepingInput.module.less';

const bookkeepingEnum = [
  { key: 'taxConsultant', value: 'TAX_CONSULTANT' },
  { key: 'selfWithConsultant', value: 'SELF_WITH_CONSULTANT' },
  { key: 'selfWithoutConsultant', value: 'SELF_WITHOUT_CONSULTANT' },
];

/**
 * It allows the user to toggle all bookkeeping options at once.
 * @component
 * @returns {JSX.Element} Checkbox for selecting or deselecting all bookkeeping options.
 * @requires useFormikField
 * @requires useTranslation
 */
const CheckAll = () => {
  const { t } = useTranslation();
  const { value, onChange } = useFormikField('bookkeeping');
  const allChecked = useMemo(() => value?.length === 3, [value]);
  const someChecked = useMemo(() => value?.length > 0 && !allChecked, [value, allChecked]);

  const onCheck = () => {
    if (allChecked) onChange([]);
    else onChange(bookkeepingEnum.map(({ value: enumValue }) => enumValue));
  };
  return (
    <Checkbox checked={allChecked} onChange={onCheck} indeterminate={someChecked}>
      {t(
        'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.bookkeeping.options.selectAll',
      )}
    </Checkbox>
  );
};

/**
 * Renders a group of checkboxes for selecting bookkeeping methods and a "Select All" option.
 * @component
 * @returns {JSX.Element} Group of checkboxes for bookkeeping options.
 * @requires useTranslation
 * @requires CheckAll
 */
const BookkeepingInput = () => {
  const { t } = useTranslation();
  const options = useMemo(
    () =>
      bookkeepingEnum.map(({ key, value }) => ({
        label: t(
          `admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.bookkeeping.options.${key}`,
        ),
        value,
      })),
    [t],
  );
  return (
    <div>
      <h4>
        {t(
          'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.bookkeeping.label',
        )}
      </h4>
      <CheckAll />
      <Checkbox.Group
        className={classes.bookkeepingInput}
        name="bookkeeping"
        options={options}
        defaultValue={['Pear']}
      />
    </div>
  );
};

export default BookkeepingInput;
