import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import InputDate, { ThisMonthButton } from 'components/common/InputDate';
import ShoppingCartFormItem from 'pages/shoppingCartManagement/ShoppingCart/components/ShoppingCartFormItem';
import Switch from 'components/common/Switch';
import { useFormikContext } from 'formik';
import inputDateClasses from './CategoryInCart.module.less';
import classes from './ServicePeriodFields.module.less';

/**
 * Renders a switch component to toggle the display of the service period fields.
 * @param {Object} props - Component properties.
 * @param {string} props.categoryId - The ID of the current category.
 * @returns {JSX.Element} The ShowPeriodSwitch component.
 */
const ShowPeriodSwitch = ({ categoryId }) => {
  const { t } = useTranslation();
  const name = `${categoryId}_showPeriod`;

  return (
    <div className={classes.showPeriodSwitchContainer}>
      <Switch name={name} label={t('user.ShoppingCart.Category.period.show')} defaultChildren />
    </div>
  );
};

/**
 * Renders start and end period date fields for the given category if the show period switch is enabled.
 * @param {Object} props - Component properties.
 * @param {string} props.categoryId - The ID of the current category.
 * @returns {JSX.Element|null} The StartEndPeriodFields component or null if the show period is disabled.
 */
const StartEndPeriodFields = ({ categoryId }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  const showPeriod = values[`${categoryId}_showPeriod`];
  if (!showPeriod) return null;

  const startPeriodName = `${categoryId}_startPeriod`;
  const endPeriodName = `${categoryId}_endPeriod`;
  return (
    <ShoppingCartFormItem name={startPeriodName}>
      <div className={classes.periodFieldsContainer}>
        <InputDate
          suffixIcon={t('user.ShoppingCart.Category.period.from')}
          className={inputDateClasses.inputData}
          name={startPeriodName}
          picker="month"
          renderExtraFooter={(props) => <ThisMonthButton {...props} name={startPeriodName} />}
        />
        <div className={classes.endPeriodContainer}>
          <InputDate
            suffixIcon={t('user.ShoppingCart.Category.period.to')}
            className={inputDateClasses.inputData}
            name={endPeriodName}
            picker="month"
            renderExtraFooter={(props) => <ThisMonthButton {...props} name={startPeriodName} />}
          />
          <span className={classes.optionalText}>{t('user.ShoppingCart.Category.period.optional')}</span>
        </div>
      </div>
    </ShoppingCartFormItem>
  );
};

/**
 * Memoized component that renders the service period fields including the switch and date inputs.
 * @param {Object} props - Component properties.
 * @param {string} props.categoryId - The ID of the current category.
 * @returns {JSX.Element} The ServicePeriodMemoFields component.
 * @component
 */
const ServicePeriodMemoFields = memo(function Period({ categoryId }) {
  return (
    <div className={classes.fieldsContainer}>
      <ShowPeriodSwitch categoryId={categoryId} />
      <StartEndPeriodFields categoryId={categoryId} />
    </div>
  );
});

export default ServicePeriodMemoFields;
