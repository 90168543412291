import { Radio } from 'formik-antd';
import FormItem from 'components/common/FormComponents/Formik/FormItem';
import { SEPA_COMPANY_DEFAULT_TEMPLATE } from 'constants/shoppingCart';

/**
 * SepaMode is a React functional component that renders a radio button group for choosing the SEPA mode.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function from i18next, used for internationalization.
 * @returns {ReactElement} The rendered component.
 *
 * @example
 * <SepaMode t={translationFunction} />
 */
export const SepaTypeSelect = ({ t, name = 'sepaDefaultTemplateTypeForCompany', isCart = false, className }) => {
  return (
    <FormItem
      tooltip={!isCart && t('admin.Setting.DigitalSignaturePreferences.sepa.sepaDefaultTemplateForCompany.tooltip')}
      name={name}
      label={t(
        `admin.Setting.DigitalSignaturePreferences.sepa.sepaDefaultTemplateForCompany.${
          isCart ? 'labelCart' : 'label'
        }`,
      )}
      className={className}
    >
      <Radio.Group name={name}>
        <Radio.Button value={SEPA_COMPANY_DEFAULT_TEMPLATE.base}>
          {t('admin.Setting.DigitalSignaturePreferences.sepa.sepaDefaultTemplateForCompany.options.sepaBase')}
        </Radio.Button>
        <Radio.Button value={SEPA_COMPANY_DEFAULT_TEMPLATE.company}>
          {t('admin.Setting.DigitalSignaturePreferences.sepa.sepaDefaultTemplateForCompany.options.sepaCompany')}
        </Radio.Button>
      </Radio.Group>
    </FormItem>
  );
};
