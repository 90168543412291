/**
 * Formats the period value for display based on the provided start and end dates.
 * @param {Object} params - The input parameters for the function.
 * @param {string} params.startPeriod - The starting date of the period.
 * @param {string} [params.endPeriod] - The optional ending date of the period.
 * @param {function} params.t - The translation function (i18n).
 * @returns {string} - Returns a formatted string representing the period value.
 * @component
 */
const formattedPeriodValue = ({ startPeriod, endPeriod, t }) => {
  if (endPeriod) {
    return `${startPeriod} ${t('user.ShoppingCart.Category.period.to')} ${endPeriod}`;
  }
  return `${t('user.ShoppingCart.Category.period.from')} ${startPeriod}`;
};

export default formattedPeriodValue;
