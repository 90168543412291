import { gql } from '@apollo/client';
import userCompanyImportFieldsFragment from '../user/userCompanyImportFieldsFragment';
import adminLegalFormsFragment from './adminLegalFormsFragment';

export const adminCompanyAddressFragment = gql`
  fragment adminCompanyAddressFragment on CompanyAddress {
    _id
    street
    houseNumber
    cityCode
    city
    country
  }
`;

const adminCompanyFragment = gql`
  fragment adminCompanyFragment on Company {
    _id
    type
    name
    identifier
    email
    address {
      ...adminCompanyAddressFragment
    }
    phoneNumber
    website
    importFields {
      ...userCompanyImportFieldsFragment
    }
    legalForm {
      ...adminLegalFormsFragment
    }
  }
  ${adminCompanyAddressFragment}
  ${userCompanyImportFieldsFragment}
  ${adminLegalFormsFragment}
`;

export default adminCompanyFragment;
