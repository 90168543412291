import ClientCompanyTypeSelect from './ClientCompanyTypeSelect';
import ClientAnnualReportSelect from './ClientAnnualReportSelect';
import ClientBookkeepingSelect from './ClientBookkeepingSelect';

/**
 * Renders a group of fields for selecting legal form details.
 * @component
 * @returns {JSX.Element} A group of legal form-related fields.
 */
const LegalFormFields = () => {
  return (
    <>
      <ClientCompanyTypeSelect />
      <ClientAnnualReportSelect />
      <ClientBookkeepingSelect />
    </>
  );
};

export default LegalFormFields;
