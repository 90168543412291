import { useTranslation } from 'react-i18next';
import DocumentTemplateSelect from 'components/common/DocumentTemplateSelect';

/**
 * SepaMandateTemplateSelect component to select a commonlibrary document template for the sepa mandate default template (Required formik context)
 * @param {'base' | 'company'} type - type of the document template
 * @returns {JSX.Element} document template select for the default templates settings for the super admin
 * @component
 */
const SepaMandateTemplateSelect = ({ type }) => {
  const { t } = useTranslation();

  const sepaType = type === 'base' ? 'sepaMandateTemplateBase' : 'sepaMandateTemplateCompany';

  return (
    <DocumentTemplateSelect
      fieldName={sepaType}
      label={t(`superAdmin.ManageDigitalSignature.defaultTemplatesCard.${sepaType}.label`)}
      placeholder={t(`superAdmin.ManageDigitalSignature.defaultTemplatesCard.${sepaType}.placeholder`)}
      tooltip={t(`superAdmin.ManageDigitalSignature.defaultTemplatesCard.${sepaType}.tooltip`)}
      isLibrary
    />
  );
};

export default SepaMandateTemplateSelect;
