import { gql } from '@apollo/client';
import userDiscountFragment from './userDiscountFragment';
import userInputFieldFragment from './userInputFieldFragment';
import userItemFragment from './userItemFragment';
import userStaticItemFragment from './userStaticItemFragment';

const userCategoryListFragment = gql`
  fragment userCategoryListFragment on Category {
    _id
    _apolloCacheKey
    introForDocument
    introForStaticItem
    name
    order
    outroForStaticItem
    subTitle
    inputFields {
      ...userInputFieldFragment
    }
    discounts {
      ...userDiscountFragment
    }
    items {
      ...userItemFragment
    }
    staticItems {
      ...userStaticItemFragment
    }
    visibility {
      annualReport
      companyTypes
      bookkeeping
    }
  }
  ${userInputFieldFragment}
  ${userDiscountFragment}
  ${userItemFragment}
  ${userStaticItemFragment}
`;

export default userCategoryListFragment;
