import i18n from 'i18n';
import confirmModal from 'utils/confirmModal';

/**
 * Displays a confirmation modal for changing the company type and returns a promise.
 * @returns {Promise<void>} A promise that resolves when the user confirms the change, and rejects if the user cancels.
 */
export const confirmCompanyChangeModal = () => {
  return new Promise((resolve, reject) => {
    confirmModal({
      cancelText: i18n.t('common.cancel'),
      okText: i18n.t('common.ok'),
      okType: 'danger',
      onCancel: () => {
        reject();
      },
      onOk: () => {
        resolve();
      },
      title: i18n.t('user.ShoppingCart.CompanyType.confirmChange'),
    });
  });
};

/**
 * Determines whether a company's legal form requires migration.
 * @param {object} company - Company data object.
 * @param {string} company.type - The type of company, e.g., 'individual' or 'company'.
 * @param {object} company.legalForm - Legal form details of the company.
 * @param {string} company.legalForm.companyType - The company's type in the legal form.
 * @param {string} company.legalForm.annualReport - The annual report status of the legal form.
 * @param {string} company.legalForm.bookkeeping - The bookkeeping status of the legal form.
 * @param {boolean} company.legalForm.manualMigration - Indicates whether manual migration has been completed.
 * @returns {boolean} `true` if the company requires legal form migration; otherwise `false`.
 */
export const checkIfLegalFormMigrationIsRequired = (company) => {
  if (company.type === 'individual') return false;
  if (
    !company.legalForm ||
    !company.legalForm.companyType ||
    !company.legalForm.annualReport ||
    !company.legalForm.bookkeeping
  )
    return true;
  return false;
};
