import { useQuery } from '@apollo/client';
import { userCompanyQuery } from 'graphql/queries';

/**
 * Hook for fetching the company of a user by its userId
 * @param {string} userId id of the user to fetch the company for
 * @param {string} [fetchPolicy='cache-and-network'] apollo fetch policy
 * @returns {object} containing the company data, error and loading state
 */
const useUserCompany = (userId, fetchPolicy = 'cache-and-network') => {
  const { data, error, loading } = useQuery(userCompanyQuery, { variables: { _id: userId }, fetchPolicy });
  const company = data?.companyById;
  return { company, error, loading };
};

export default useUserCompany;
