import { Skeleton, Form } from 'antd';
import { ControlOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import { useCachedQuery } from 'graphql/utils';
import { userDigitalSignatureEnabledGlobalStateQuery } from 'graphql/queries';
import Card from 'components/common/Card';
import DigitalSignatureEnabled from './GeneralSettingsCard/DigitalSignatureEnabled';
import NotifyClientSigneesForDigitalSignatureStartTemplateSelect from './GeneralSettingsCard/NotifyClientSigneesForDigitalSignatureStartTemplateSelect';
import NotifyUsersByMailSwitch from './GeneralSettingsCard/NotifyUsersByMailSwitch';
import NotifyTenantSigneesForDigitalSignatureStartTemplateSelect from './GeneralSettingsCard/NotifyTenantSigneesForDigitalSignatureStartTemplateSelect';
import DefaultLinkValidityTimeInput from './GeneralSettingsCard/DefaultLinkValidityTimeInput';
import DefaultTenantSigneesSelect from './GeneralSettingsCard/DefaultTenantSigneesSelect';
import AutoSignForTenantSigneesEnabledSwitch from './GeneralSettingsCard/AutoSignForTenantSigneesEnabledSwitch';
import PasswordEnabledSwitch from './GeneralSettingsCard/PasswordEnabledSwitch';
import SendPasswordToSigneesTemplateSelect from './GeneralSettingsCard/SendPasswordToSigneesTemplateSelect';

const GeneralSettingsCard = () => {
  const { t } = useTranslation();
  const { loading, data: digitalSignatureEnabledGloballyQueryResult } = useCachedQuery(
    userDigitalSignatureEnabledGlobalStateQuery,
  );
  const { value: digitalSignatureEnabled } = useFormikField('digitalSignatureEnabled');
  const { value: notifyUsersByMail } = useFormikField('notifyUsersByMail');

  const componentsForDigitalSignatureEnabled = (
    <>
      <NotifyClientSigneesForDigitalSignatureStartTemplateSelect t={t} />
      <NotifyUsersByMailSwitch t={t} />
      {notifyUsersByMail && <NotifyTenantSigneesForDigitalSignatureStartTemplateSelect t={t} />}
      <DefaultLinkValidityTimeInput t={t} />
      <DefaultTenantSigneesSelect t={t} />
      <AutoSignForTenantSigneesEnabledSwitch t={t} />
      <PasswordEnabledSwitch t={t} />
      <SendPasswordToSigneesTemplateSelect t={t} />
    </>
  );

  return (
    <Card
      icon={<ControlOutlined />}
      title={t('admin.Setting.DigitalSignaturePreferences.generalSettings.cardLabel')}
      style={{ padding: 0 }}
    >
      {loading && !digitalSignatureEnabledGloballyQueryResult ? <Skeleton /> : null}
      {digitalSignatureEnabledGloballyQueryResult ? (
        <Form layout="vertical">
          <DigitalSignatureEnabled
            t={t}
            disabled={!digitalSignatureEnabledGloballyQueryResult.getGlobalDigitalSignatureEnabledState}
          />
          {digitalSignatureEnabled ? componentsForDigitalSignatureEnabled : <DefaultTenantSigneesSelect t={t} />}
        </Form>
      ) : null}
    </Card>
  );
};

export default GeneralSettingsCard;
