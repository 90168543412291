import { useEffect } from 'react';
import Card from 'components/common/Card';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FaSignature } from 'react-icons/fa';
import useUsersInTenantList from 'hooks/user/useUsersInTenantList';
import useContactList from 'hooks/user/useContactList';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import Switch from 'components/common/Switch';
import SelectInputField from 'components/common/SelectInputField';
import usePreferences from 'hooks/user/usePreferences';
import { useDocumentTemplatesForShoppingCartContext } from 'contexts/DocumentTemplatesForShoppingCartContext';
import { useFormikField } from 'hooks/common/useFormikField';
import { SepaEnable } from 'components/admin/DigitalSignaturePreferences/SEPASettings/SepaEnable';
import { SepaMode } from 'components/admin/DigitalSignaturePreferences/SEPASettings/SepaMode';
import IndentWrapper from 'components/common/IndentWrapper';
import { SepaTypeSelect } from 'components/admin/DigitalSignaturePreferences/SEPASettings/SepaCompanyDefaultSelect';
import useUserCompany from 'hooks/user/useUserCompany';
import { Alert } from 'antd';
import { formatDate } from 'utils/formatDate';
import ErrorAlert from './ErrorAlert';
import classes from './SignatureCard.module.less';
import { filterKanzleipilotTeamUsers } from './utils';

/** @namespace ShoppingCartEdit */

/**
 * @enum {String}
 */
export const SIGNABLE = {
  BOTH: 'BOTH',
  ONLY_TENANT: 'ONLY_TENANT',
  ONLY_COMPANY: 'ONLY_COMPANY',
  NOT_SIGNABLE: 'NOT_SIGNABLE',
};

/**
 * Checks which signable state all docs result in
 * @param {SIGNABLE[]} isSignablePropsOfSelectedDocumentTemplates - Collection of isSignable prop of all selected document templates
 * @returns {SIGNABLE} returns both signable if min one both signable doc temp is included or min one tenant only and one company only, else if min one tenant only doc temp is included it returns tenant only, else if min one company only doc temp is included it returns company only else returns not signable
 */
const getSignatureCardIsSignableProp = (isSignablePropsOfSelectedDocumentTemplates) => {
  if (!isSignablePropsOfSelectedDocumentTemplates || isSignablePropsOfSelectedDocumentTemplates.length === 0)
    return SIGNABLE.NOT_SIGNABLE;
  const foundBoth = isSignablePropsOfSelectedDocumentTemplates.find((isSignable) => isSignable === SIGNABLE.BOTH);
  if (foundBoth) return SIGNABLE.BOTH;
  const foundOnlyCompany = isSignablePropsOfSelectedDocumentTemplates.find(
    (isSignable) => isSignable === SIGNABLE.ONLY_COMPANY,
  );
  const foundOnlyTenant = isSignablePropsOfSelectedDocumentTemplates.find(
    (isSignable) => isSignable === SIGNABLE.ONLY_TENANT,
  );
  if (foundOnlyCompany && foundOnlyTenant) return SIGNABLE.BOTH;
  if (foundOnlyCompany) return SIGNABLE.ONLY_COMPANY;
  if (foundOnlyTenant) return SIGNABLE.ONLY_TENANT;
  return SIGNABLE.NOT_SIGNABLE;
};

/**
 * Wrapper component for SignatureCard, which hides card, if card shouldn't visible</br>
 * Needs formik context of shopping cart edit/creation
 * (Needs a document template for shopping cart context)
 * @component
 * @returns {Object} Null or SignatureCard component
 */
const SignatureCardWrapper = () => {
  const { values, setFieldValueAndTouched, initialValues } = useFormikContext();
  const { allDocumentTemplateOptions } = useDocumentTemplatesForShoppingCartContext();
  const { preferences } = usePreferences({ fetchPolicy: 'cache-and-network' });

  const isSignablePropsOfSelectedDocumentTemplates = values?.documentTemplates?.map(
    (selectedTemplate) =>
      allDocumentTemplateOptions?.find((template) => template.value === selectedTemplate)?.isSignable,
  );

  const signableDocumentSelected = isSignablePropsOfSelectedDocumentTemplates.find(
    (isSignable) => isSignable !== SIGNABLE.NOT_SIGNABLE,
  );

  const enableDigitalSignature =
    preferences?.digitalSignaturePreferences?.digitalSignatureEnabled && signableDocumentSelected;

  const sepaEnabled = preferences?.digitalSignaturePreferences?.sepaEnabled;

  useEffect(() => {
    if (!enableDigitalSignature) setFieldValueAndTouched('enableDigitalSignature', false);
    else setFieldValueAndTouched('enableDigitalSignature', initialValues?.enableDigitalSignature);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableDigitalSignature]);

  if (!signableDocumentSelected) {
    return null;
  }

  const isSignable = getSignatureCardIsSignableProp(isSignablePropsOfSelectedDocumentTemplates);

  return <SignatureCard isSignable={isSignable} sepaEnabled={sepaEnabled} />;
};

/**
 * SignatureCard component for signee select in shopping cart edit/creation</br>
 * Needs formik context of shopping cart edit/creation
 * @param {Object} inputProperties - Input properties of component
 * @param {SIGNABLE} inputProperties.isSignable - Resulted isSignable property of all selected document templates
 * @component
 * @returns {Object} Card component with signee select and switch for enabling ds for the shopping cart
 */
const SignatureCard = ({ isSignable, sepaEnabled }) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValueAndTouched, setFieldValue } = useFormikContext();
  const { data: users } = useUsersInTenantList();
  const { data: contacts } = useContactList(values.initializationConfigDateForCompany);
  const { preferences } = usePreferences({ fetchPolicy: 'cache-and-network' });
  const [currentUser] = useCurrentUser();
  const { company } = useUserCompany(values.companyId, 'cache-and-network');

  const isForDigitalSignature = preferences?.digitalSignaturePreferences.digitalSignatureEnabled;
  const emailAddressError = /.*missingEmailAddress/.test(errors.companySignees);

  const tenantSigneesOptions = users
    ?.filter((user) => filterKanzleipilotTeamUsers(user, currentUser))
    .map(mapUsersToOptions);

  const companySigneesOption = values.contacts.map((contact) => {
    const contactProps = contacts?.find((fullContact) => fullContact._id === contact._id);
    const positionExtension = contactProps && contactProps.position && `(${contactProps.position})`;
    return {
      label: contactProps ? `${contactProps.firstName} ${contactProps.lastName} ${positionExtension}` : contact._id,
      value: JSON.stringify({
        _id: contact?._id || null,
        email: contact?.email || null,
      }),
    };
  });

  // Change switch state to false, if switch isn't rendered
  useEffect(() => {
    if (!isForDigitalSignature && values.enableDigitalSignature) {
      setFieldValueAndTouched('enableDigitalSignature', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForDigitalSignature, values]);

  // Update selected company signees, if contacts have changed after 100ms
  useEffect(() => {
    if (values.companySignees && values.companySignees.length > 0) {
      setTimeout(() => {
        const updatedSelectedContacts = values.companySignees
          .map((contact) => {
            const contactObj = JSON.parse(contact);
            const contactInContactsSelection = values.contacts?.find(
              (contactOfSelection) => contactObj._id === contactOfSelection._id,
            );
            if (!contactInContactsSelection) return null;
            const currentContactData = contacts?.find((fullContact) => fullContact._id === contactObj._id);
            if (!currentContactData) return null;
            return JSON.stringify({
              _id: currentContactData?._id || null,
              email: currentContactData?.email || null,
            });
          })
          .filter(Boolean);
        setFieldValue('companySignees', updatedSelectedContacts, true);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, values.contacts]);

  // Add current user, if this option is selected as default option
  useEffect(() => {
    if (preferences?.digitalSignaturePreferences?.defaultTenantSignees?.length > 0 && currentUser) {
      const preSelectedSignees = preferences?.digitalSignaturePreferences?.defaultTenantSignees.filter(
        (signee) => signee !== 'CURRENT_USER',
      );
      if (
        preferences?.digitalSignaturePreferences?.defaultTenantSignees.find((signee) => signee === 'CURRENT_USER') &&
        !preSelectedSignees.find((signeeId) => signeeId === currentUser._id)
      )
        preSelectedSignees.push(currentUser._id);
      setFieldValue('tenantSignees', preSelectedSignees);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, preferences, currentUser]);

  const { enableDigitalSignature } = values;

  return (
    <Card bordered icon={<FaSignature />} title={t('user.ShoppingCart.SignatureCard.label')}>
      {isForDigitalSignature ? (
        <div className={classes.enableDigitalSignatureSwitch}>
          <Switch
            label={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.label')}
            name="enableDigitalSignature"
            checkedChildren={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.on')}
            unCheckedChildren={t('user.ShoppingCart.SignatureCard.digitalSignatureSwitch.off')}
          />
        </div>
      ) : null}
      <SigneeSelection
        isSignable={isSignable}
        companySigneesOption={companySigneesOption}
        tenantSigneesOptions={tenantSigneesOptions}
        emailAddressError={emailAddressError}
      />
      {emailAddressError && (
        <ErrorAlert className={classes.emailAddressesAlert}>
          {t('user.ShoppingCart.SignatureCard.errorMessage')}
        </ErrorAlert>
      )}
      <SepaSettings
        sepaEnabled={sepaEnabled}
        enableDigitalSignature={enableDigitalSignature}
        preferences={preferences}
        company={company}
      />
    </Card>
  );
};

/**
 * SigneeSelection component for selecting signees in the SignatureCard. It displays select fields based on the `isSignable` configuration.
 * @param {object} inputProperties - Input properties of the component.
 * @param {boolean} inputProperties.isSignable - Specifies which signee options should be shown (BOTH, ONLY_COMPANY, ONLY_TENANT).
 * @param {array} inputProperties.companySigneesOption - Array of options for company signees.
 * @param {array} inputProperties.tenantSigneesOptions - Array of options for tenant signees.
 * @param {boolean} inputProperties.emailAddressError - Flag to indicate if there's an error related to the input.
 * @returns {JSX.Element} A selection component for company and tenant signees.
 * @component
 */
const SigneeSelection = ({ isSignable, companySigneesOption, tenantSigneesOptions, emailAddressError }) => {
  const { t } = useTranslation();

  return (
    <>
      {isSignable === SIGNABLE.BOTH || isSignable === SIGNABLE.ONLY_COMPANY ? (
        <SelectInputField
          name="companySignees"
          options={companySigneesOption}
          label={t('user.ShoppingCart.SignatureCard.companySigneesSelect.label')}
          enableErrorHandling={!emailAddressError}
          errorComponent={ErrorAlert}
        />
      ) : null}
      {isSignable === SIGNABLE.BOTH || isSignable === SIGNABLE.ONLY_TENANT ? (
        <SelectInputField
          name="tenantSignees"
          options={tenantSigneesOptions}
          label={t('user.ShoppingCart.SignatureCard.tenantSigneesSelect.label')}
          enableErrorHandling
          errorComponent={ErrorAlert}
        />
      ) : null}
    </>
  );
};

/**
 * Sepa mandate list component for signature card SepaInfoWrapper
 * @param {object} inputProperties - Component inputProperties
 * @param {array} inputProperties.bankAccounts - Array of bank accounts
 * @param {function} t - Translate function
 * @returns {ReactElement} Sepa mandate list
 */
const SepaInfoWrapperDescription = ({ bankAccounts, t }) =>
  bankAccounts.length > 0 ? (
    <>
      {t('user.ShoppingCart.SignatureCard.sepaMandateInfo.label')}
      <ul>
        {bankAccounts.map(({ sepaMandateGrantedAt, sepaMandateType }) => (
          <li>
            {t('user.ShoppingCart.SignatureCard.sepaMandateInfo.mandateTemplate', {
              date: formatDate(sepaMandateGrantedAt),
              sepaMandateType,
            })}
          </li>
        ))}
      </ul>
    </>
  ) : (
    t('user.ShoppingCart.SignatureCard.sepaMandateInfo.noMandate')
  );

/**
 * Wrapper component for information about SEPA accounts
 * @param {object} inputProperties - Component inputProperties
 * @param {array} inputProperties.bankAccounts - Array of bank accounts
 * @param {function} t - Translate function
 * @returns {ReactElement} Information about SEPA accounts
 */
export const SepaInfoWrapper = ({ bankAccounts, t }) => {
  const alertType = bankAccounts.length > 0 ? 'warning' : 'info';
  return (
    <Alert
      className={`alert-${alertType}`}
      type={alertType}
      showIcon
      description={<SepaInfoWrapperDescription bankAccounts={bankAccounts} t={t} />}
    />
  );
};

/**
 * Settings component for SEPA-related configurations in the SignatureCard.
 * @param {object} inputProperties - Input properties of the component
 * @param {boolean} inputProperties.sepaEnabled - Indicates if SEPA is enabled in the global preferences
 * @param {boolean} inputProperties.enableDigitalSignature - Indicates if digital signature is enabled in the global preferences
 * @param {object} inputProperties.preferences - Tenant preferences, including digitalSignaturePreferences
 * @param {object} inputProperties.company - The company object
 * @returns {JSX.Element} SEPA settings component
 * @component
 */
const SepaSettings = ({ sepaEnabled, enableDigitalSignature, preferences, company }) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { value: sepaCustomSettings } = useFormikField('sepaCustomSettings');
  const { value: sepaCustomEnabled } = useFormikField('sepaEnabled');
  const { value: companyTypeId } = useFormikField('companyTypeId');

  const bankAccountsWithSepa = company?.bankAccounts?.filter((ba) => ba.hasSepaMandate) ?? [];

  // If sepaCustomSettings is switched off, use global signature settings
  useEffect(() => {
    if (sepaCustomSettings === false) {
      const isPrivatePerson = company?.type === 'individual' || companyTypeId === 'privatePerson';
      setFieldValue('sepaEnabled', preferences?.digitalSignaturePreferences.sepaEnabled);
      setFieldValue('sepaMode', preferences?.digitalSignaturePreferences.sepaMode);
      setFieldValue(
        'sepaType',
        isPrivatePerson ? 'sepaBase' : preferences?.digitalSignaturePreferences.sepaDefaultTemplateTypeForCompany,
      );
    } else {
      setFieldValue('sepaEnabled', !bankAccountsWithSepa.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sepaCustomSettings, enableDigitalSignature]);

  if (!sepaEnabled || !enableDigitalSignature) return null;

  return (
    <div className={classes.sepaCustomSettings}>
      <SepaInfoWrapper t={t} bankAccounts={bankAccountsWithSepa} />
      <SepaCustomSettings />
      {sepaCustomSettings && (
        <IndentWrapper marginTopWrapper="6px" marginTopContent="10px">
          <SepaEnable t={t} />
          {sepaCustomEnabled && (
            <div className={classes.sepaCustomSettings}>
              <SepaMode className={classes.sepaCustomSettings} t={t} />
              <SepaTypeSelect className={classes.sepaCustomSettings} t={t} name="sepaType" isCart />
            </div>
          )}
        </IndentWrapper>
      )}
    </div>
  );
};

/**
Switch for enabling custom sepa settings for signature card in project card creation
@param {object} inputProperties - Input properties of the component
@returns {JSX.Element} Switch for sepa custom settings
@component
*/
export const SepaCustomSettings = () => {
  const { t } = useTranslation();

  return (
    <Switch
      name="sepaCustomSettings"
      label={t('user.ShoppingCart.SignatureCard.sepaCustomSettings.label')}
      tooltip={t('user.ShoppingCart.SignatureCard.sepaCustomSettings.tooltip')}
    />
  );
};

export default SignatureCardWrapper;

/**
 * Map users to options for selecting signees
 * @param {Object} user - Input properties of component
 * @param {Obejct} user.profile - Profile of current user
 * @param {String} user.profile.firstName - First name of current user
 * @param {String} user.profile.lastName - Last name of current user
 * @param {String} user._id - Id of current user
 * @returns {Object} Object with label and value for select component
 */
const mapUsersToOptions = (user) => {
  return {
    label: `${user.profile.firstName} ${user.profile.lastName}`,
    value: user._id,
  };
};
