import { gql } from '@apollo/client';

const userCompanyAddressFragment = gql`
  fragment userCompanyAddressFragment on CompanyAddress {
    _id
    addition
    street
    houseNumber
    cityCode
    city
    country
  }
`;

export default userCompanyAddressFragment;
