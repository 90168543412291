import { useMemo } from 'react';
import { CompanyTypeUtility } from '@JavaScriptSuperstars/kanzleipilot-shared';

/**
 * Custom hook that provides memoized company types by instantiating the CompanyTypeUtility class and calling its `getAll` method.
 * Optionally filters out company types based on the `filterOut` parameter.
 * @param {object} options - The options object for filtering company types.
 * @param {Array<string>} [options.filterOut=[]] - An optional array of companyType values to filter out.
 * @returns {Array<object>} The filtered list of company types.
 */
const useCompanyTypes = ({ filterOut = [] } = {}) => {
  const instance = useMemo(() => new CompanyTypeUtility(), []);
  const companyTypes = useMemo(() => {
    const allCompanyTypes = instance.getAll();
    if (filterOut.length > 0) {
      return allCompanyTypes.filter((company) => !filterOut.includes(company.companyType));
    }
    return allCompanyTypes;
  }, [instance, filterOut]);

  return companyTypes;
};

export default useCompanyTypes;
