import { useMemo } from 'react';
import { Alert, Form, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import Card from 'components/common/Card';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import { useQuery } from '@apollo/client';
import { adminDefaultTemplatesQuery, adminDocumentTemplateListQuery, adminEmailTemplatesQuery } from 'graphql/queries';
import { getMessageFromGraphQLError } from 'utils/helpers';
import { useCachedQuery } from 'graphql/utils';
import { updateDefaultTemplates } from 'graphql/methods';
import StartClientNotificationTemplateSelect from './StartClientNotificationTemplateSelect';
import NoticeOfCancellationTemplateSelect from './NoticeOfCancellationTemplateSelect';
import SepaMandateTemplateSelect from './SepaMandateTemplateSelect';
import StartTenantNotificationTemplateSelect from './StartTenantNotificationTemplateSelect';
import PasswordEmailTemplateSelect from './PasswordEmailTemplateSelect';
import RevokeEmailTemplateSelect from './RevokeEmailTemplateSelect';
import RemindEmailTemplateSelect from './RemindEmailTemplateSelect';
import SignedBySigneeNotificationTemplateSelect from './SignedBySigneeNotificationTemplateSelect';
import OpenedBySigneeNotificationTemplateSelect from './OpenedBySigneeNotificationTemplateSelect';
import SuccessfulFinishedNotificationTemplateSelect from './SuccessfulFinishedNotificationTemplateSelect';

/**
 * DefaultTemplatesCard component contains settings for the super admin to set default templates for the digital signature settings
 * @returns {JSX.Element} card for the manage digital signature globally page to set default templates
 * @component
 */
const DefaultTemplatesCard = () => {
  const { t } = useTranslation();
  const {
    data: defaultTemplates,
    loading,
    error: getDefaultTemplatesError,
  } = useQuery(adminDefaultTemplatesQuery, { fetchPolicy: 'cache-and-network' });
  const {
    data: emailTemplatesData,
    loading: emailTemplatesLoading,
    error: emailTemplatesError,
  } = useCachedQuery(adminEmailTemplatesQuery, {
    variables: { isLibrary: true },
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: documentTemplatesData,
    loading: documentTemplatesLoading,
    error: documentTemplatesError,
  } = useCachedQuery(adminDocumentTemplateListQuery, {
    variables: { isLibrary: true },
    fetchPolicy: 'cache-and-network',
  });
  const initValues = useMemo(() => {
    const fetchedDefaultTemplates = defaultTemplates?.getDigitalSignatureDefaultTemplates;
    return {
      noticeOfCancellationTemplate: fetchedDefaultTemplates?.noticeOfCancellationTemplate || null,
      sepaMandateTemplateBase: fetchedDefaultTemplates?.sepaMandateTemplateBase || null,
      sepaMandateTemplateCompany: fetchedDefaultTemplates?.sepaMandateTemplateCompany || null,
      startClientNotificationTemplate: fetchedDefaultTemplates?.startClientNotificationTemplate || null,
      startTenantNotificationTemplate: fetchedDefaultTemplates?.startTenantNotificationTemplate || null,
      passwordEmailTemplate: fetchedDefaultTemplates?.passwordEmailTemplate || null,
      revokeEmailTemplate: fetchedDefaultTemplates?.revokeEmailTemplate || null,
      remindEmailTemplate: fetchedDefaultTemplates?.remindEmailTemplate || null,
      signedBySigneeNotificationTemplate: fetchedDefaultTemplates?.signedBySigneeNotificationTemplate || null,
      openedBySigneeNotificationTemplate: fetchedDefaultTemplates?.openedBySigneeNotificationTemplate || null,
      successfulFinishedNotificationTemplate: fetchedDefaultTemplates?.successfulFinishedNotificationTemplate || null,
    };
  }, [defaultTemplates]);

  const isData = defaultTemplates && emailTemplatesData && documentTemplatesData;
  const isSomethingLoading = loading || emailTemplatesLoading || documentTemplatesLoading;
  const error = getDefaultTemplatesError || emailTemplatesError || documentTemplatesError;
  const skeletons = [...new Array(2)].map((_, i) => i + 1);

  const onSubmit = async ({
    noticeOfCancellationTemplate,
    sepaMandateTemplateBase,
    sepaMandateTemplateCompany,
    startClientNotificationTemplate,
    startTenantNotificationTemplate,
    passwordEmailTemplate,
    revokeEmailTemplate,
    remindEmailTemplate,
    signedBySigneeNotificationTemplate,
    openedBySigneeNotificationTemplate,
    successfulFinishedNotificationTemplate,
  }) => {
    return updateDefaultTemplates({
      noticeOfCancellationTemplate: noticeOfCancellationTemplate || null,
      sepaMandateTemplateBase: sepaMandateTemplateBase || null,
      sepaMandateTemplateCompany: sepaMandateTemplateCompany || null,
      startClientNotificationTemplate: startClientNotificationTemplate || null,
      startTenantNotificationTemplate: startTenantNotificationTemplate || null,
      passwordEmailTemplate: passwordEmailTemplate || null,
      revokeEmailTemplate: revokeEmailTemplate || null,
      remindEmailTemplate: remindEmailTemplate || null,
      signedBySigneeNotificationTemplate: signedBySigneeNotificationTemplate || null,
      openedBySigneeNotificationTemplate: openedBySigneeNotificationTemplate || null,
      successfulFinishedNotificationTemplate: successfulFinishedNotificationTemplate || null,
    });
  };

  return (
    <Card title={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.title')}>
      <Alert
        type="info"
        message={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.infoAlert.title')}
        description={t('superAdmin.ManageDigitalSignature.defaultTemplatesCard.infoAlert.description')}
        className="alert-info"
        showIcon
      />
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {!isData && isSomethingLoading ? skeletons.map((k) => <Skeleton title loading active key={k} />) : null}
      {isData ? (
        <I18nFormik initialValues={initValues} onSubmit={onSubmit} enableReinitialize>
          <Form layout="vertical">
            <NoticeOfCancellationTemplateSelect />
            <SepaMandateTemplateSelect type="base" />
            <SepaMandateTemplateSelect type="company" />
            <StartClientNotificationTemplateSelect />
            <StartTenantNotificationTemplateSelect />
            <PasswordEmailTemplateSelect />
            <RevokeEmailTemplateSelect />
            <RemindEmailTemplateSelect />
            <OpenedBySigneeNotificationTemplateSelect />
            <SignedBySigneeNotificationTemplateSelect />
            <SuccessfulFinishedNotificationTemplateSelect />
          </Form>
          <SaveChangesButton initialValues={initValues} />
        </I18nFormik>
      ) : null}
    </Card>
  );
};

export default DefaultTemplatesCard;
