import { useTranslation } from 'react-i18next';
import Alert from 'components/common/Alert';

/**
 * Renders a localized alert message for force migration.
 * @returns {JSX.Element} A div containing the migration alert message.
 */
const MigrationAlert = () => {
  const { t } = useTranslation();
  return (
    <Alert
      className="alert-info"
      type="info"
      showIcon
      description={<div>{t('ClientsListPage.addClientModal.migrationModal.notice')}</div>}
    />
  );
};

export default MigrationAlert;
