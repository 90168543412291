import apollo from 'graphql/apollo';
import { deleteCompanyContactMutation } from 'graphql/mutations';
import { userCompanyQuery, userContactsQuery } from 'graphql/queries';

/**
 * Delete contact remotely
 * @param {string} clientId - id of client
 * @param {string} contactId - id of contact
 * @returns {Promise} - Promise object represents the result of the mutation
 */
const deleteContact = async (clientId, contactId) => {
  return apollo.mutate({
    mutation: deleteCompanyContactMutation,
    variables: {
      clientId,
      contactId,
    },
    refetchQueries: [
      {
        query: userCompanyQuery,
        variables: { _id: clientId },
      },
      {
        query: userContactsQuery,
      },
    ],
  });
};

export default deleteContact;
