/**
 * Format a date to a readable date string
 * @param {Date | string} date - Date to render, example: Wed Jan 15 2025 13:33:45 GMT+0100 (Central European Standard Time) or new Date()
 * @returns {string} - Formatted date, example: 15.01.2025
 */
export const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('de-DE', {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};
