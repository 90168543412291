import ArrowIcon from 'components/common/ArrowIcon';
import classes from './IndentWrapper.module.less';

/**
 * IndentWrapper component that adds a visual indentation and a left margin to the content it wraps, with an arrow icon as an indicator.
 * @param {object} inputProperties - Input properties of the component
 * @param {ReactNode} inputProperties.children - The content to be wrapped with indentation
 * @param {string} [inputProperties.indentWidth='50px'] - Width of the indentation block
 * @param {string} [inputProperties.marginTopWrapper='16px'] - Top margin applied to the wrapper
 * @param {string} [inputProperties.marginLeftContent='16px'] - Left margin applied to the content inside the wrapper
 * @param {string} [inputProperties.marginTopContent='0'] - Top margin applied to the content inside the wrapper
 * @returns {JSX.Element} Indented wrapper with a customizable arrow icon
 * @component
 */
const IndentWrapper = ({
  children,
  indentWidth = '50px',
  marginTopWrapper = '16px',
  marginLeftContent = '16px',
  marginTopContent = '0',
}) => {
  return (
    <div className={classes.flexContainer} style={{ marginTop: marginTopWrapper }}>
      <div className={classes.leftContainer} style={{ width: indentWidth }}>
        <ArrowIcon />
      </div>
      <div style={{ marginLeft: marginLeftContent, marginTop: marginTopContent }}>{children}</div>
    </div>
  );
};

export default IndentWrapper;
