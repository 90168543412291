import { FaHome } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Card from 'components/common/Card';
import { Col, Row, Alert, Button } from 'antd';
import { memo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import equal from 'fast-deep-equal';
import CompanyField from './company/CompanyField';
import ContactsField from './contacts/ContactsField';
import { useIsOldContactData } from './hooks';

const FetchFromGlobalButton = () => {
  const { t } = useTranslation();
  const { setValues } = useFormikContext();
  const onFetch = useCallback(async () => {
    setValues((s) => ({
      ...s,
      initializationConfigDateForCompany: undefined,
    }));
  }, [setValues]);

  return <Button onClick={onFetch}>{t('user.ShoppingCart.ContactData.fetchFromGlobal')}</Button>;
};

const FetchFromGlobalButtonMemo = memo(FetchFromGlobalButton, equal);

/**
 * Component to display and manage contact data, including company and contact fields.
 * @param {object} props - Component properties.
 * @param {function} [props.onSubmitForm=() => {}] - Callback function to handle form submission for company details.
 * @returns {JSX.Element} A card displaying contact data fields.
 * @component
 */
const ContactData = ({ onSubmitForm = () => {} }) => {
  const { t } = useTranslation();
  const isOldContactData = useIsOldContactData();
  return (
    <Card bordered icon={<FaHome />} title={t('user.ShoppingCart.ContactData.label')}>
      {isOldContactData ? (
        <Alert
          description={<FetchFromGlobalButtonMemo />}
          message={t('user.ShoppingCart.ContactData.oldData')}
          showIcon
        />
      ) : null}
      <Row gutter={20}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <CompanyField onSubmitForm={onSubmitForm} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          <ContactsField />
        </Col>
      </Row>
    </Card>
  );
};

export default ContactData;
