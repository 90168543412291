/* eslint-disable no-nested-ternary */
import equal from 'fast-deep-equal/es6/react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CalculationMode } from 'constants/item';
import { Calc } from '@JavaScriptSuperstars/kanzleipilot-shared';

/**
 * Renders price information based on the given priceTranslation object.
 * If the priceTranslation object is of type 'code_on_actual_cost' and has a minPrice and/or a maxPrice set,
 * it will render a translated string for the min and/or max price.
 * If shouldUseMinMaxPriceAdvanced is set to true, it will render a translated string for the min and/or max price
 * with "Höchsthonorar" and/or "Mindesthonorar" instead of "maximal" and/or "mindestens".
 * @param {Object} props - The component props.
 * @param {Object} props.priceTranslation - The price translation object.
 * @param {boolean} props.shouldUseMinMaxPriceAdvanced - Whether to use the advanced min-max price formatting.
 * @param {string} props.minMaxTranslation - The translation key for the min-max price information.
 * @param {boolean} props.showDigits - Whether to show decimal digits in the price formatting.
 * @returns {JSX.Element|null} The rendered price information or null if the priceTranslation object is not of type 'code_on_actual_cost'.
 */
const TranslationInfo = ({ priceTranslation, shouldUseMinMaxPriceAdvanced, minMaxTranslation, showDigits }) => {
  if (
    priceTranslation.code !== 'code_on_actual_cost' ||
    (priceTranslation.variables.minPrice <= 0 && priceTranslation.variables.maxPrice <= 0)
  ) {
    return null;
  }

  const formattedMinPrice = Calc.formatCurrency(priceTranslation.variables.minPrice, {
    showDigits,
  });

  const formattedMaxPrice = Calc.formatCurrency(priceTranslation.variables.maxPrice, {
    showDigits,
  });

  const commonValues = {
    values: {
      minPrice: formattedMinPrice,
      maxPrice: formattedMaxPrice,
    },
    components: { i: <i />, br: <br /> },
  };

  return shouldUseMinMaxPriceAdvanced ? (
    <Trans i18nKey={`sharedPackage.minMaxPriceOnActualCostAdvanced.${minMaxTranslation}`} {...commonValues} />
  ) : (
    <Trans i18nKey={`sharedPackage.minMaxPriceOnActualCost.${minMaxTranslation}`} {...commonValues} />
  );
};

/**
 * Determines the type of price range based on min and max values.
 * @param {Object} props - The variables containing minPrice and maxPrice
 * @param {number} props.minPrice - The minimum price value.
 * @param {number} props.maxPrice - The maximum price value.
 * @returns {string} The type of price range ('minmax', 'min', 'max', or '')
 */
const getMinMaxTranslationType = ({ minPrice, maxPrice }) => {
  const hasMinPrice = minPrice > 0;
  const hasMaxPrice = maxPrice > 0;

  if (hasMinPrice && hasMaxPrice) return 'minmax';
  if (hasMinPrice) return 'min';
  if (hasMaxPrice) return 'max';
  return '';
};

/**
 * This component renders a translated price with optional formatting and decorations based on calculation mode.
 * @component
 * @param {Object} props - The props for the component.
 * @param {Object} props.currentFeeTypeMessage - The current fee type message.
 * @param {Object} props.priceTranslation - The price translation object containing variables and code.
 * @param {number} props.customPrice - The custom price value.
 * @param {boolean} props.showCalculatedPrice - Whether to show the calculated price.
 * @param {boolean} props.shouldUseMinMaxPriceAdvanced - Whether to use advanced min-max price formatting.
 * @param {boolean} props.showDigits - Whether to show decimal digits in the price formatting.
 * @returns {JSX.Element} The formatted price component.
 */
const TranslationPrice = ({
  currentFeeTypeMessage,
  priceTranslation,
  customPrice,
  showCalculatedPrice,
  shouldUseMinMaxPriceAdvanced,
  showDigits,
}) => {
  const { t } = useTranslation();

  const isTextDecoration =
    priceTranslation.variables.calculationMode !== CalculationMode.ON_ACTUAL_COST &&
    Number.isFinite(customPrice) &&
    (currentFeeTypeMessage || !showCalculatedPrice);

  const minMaxTranslation = getMinMaxTranslationType(priceTranslation.variables);

  return (
    <span className={isTextDecoration ? 'text-decoration-style-dotted' : ''}>
      <Trans
        i18nKey={`sharedPackage.${priceTranslation.code}`}
        components={{ del: <del />, br: <br /> }}
        values={{
          ...priceTranslation.variables,
          newlineOrWhitespace: '<br/>',
          paymentInterval: t(`common.Item.paymentIntervalValue.${priceTranslation.variables.paymentInterval}`, {
            defaultValue: '',
          }),
          separator: ',',
        }}
      />

      <TranslationInfo
        priceTranslation={priceTranslation}
        minMaxPriceAdvanced={shouldUseMinMaxPriceAdvanced}
        minMaxTranslation={minMaxTranslation}
        showDigits={showDigits}
      />
    </span>
  );
};

export default memo(TranslationPrice, equal);
