import { Form } from 'formik-antd';
import { memo, useMemo } from 'react';
import { AlertFromFormik } from 'components/common/ErrorComponent';
import SaveChangesButton from 'components/common/FormComponents/Formik/SaveChangesButton';
import I18nFormik from 'components/common/FormComponents/Formik/I18nFormik';
import equal from 'fast-deep-equal/es6/react';
import { useTranslation } from 'react-i18next';
import { adminTenantCompanyQuery } from 'graphql/queries';
import { useCachedQuery } from 'graphql/utils';
import { grabFirstGQLDataResult } from 'utils/helpers';
import { updateCategory, updateCategoryItem } from 'graphql/methods';
import MoreInfoWidget from 'components/common/MoreInfoWidget';
import confirmModal from 'utils/confirmModal';
import { useLibraryContext } from 'contexts/LibraryContext';
import useCompanyTypes from 'hooks/user/useCompanyTypes';
import CompanyTypeTransfer from './CompanyTypeTransfer';
import AnnualReportInput from './AnnualReportInput';
import BookkeepingInput from './BookeepingInput';
import { COMPANY_TYPE_CONFLICTS, doCompanyTypesConflict } from './utils';
import classes from './ConditionalVisibilityTab.module.less';

export const ConditionalVisibilityHelperWidget = ({ parentType }) => {
  const { t } = useTranslation();
  return (
    <MoreInfoWidget
      buttonText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.howUseButton`,
      )}
      title={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.title`,
      )}
      helpText={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.helpText`,
      )}
      videoCaption={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoCaption`,
      )}
      videoUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.videoUrl`,
      )}
      imageUrl={t(
        `admin.${
          parentType === 'categoryItem' ? 'itemModal' : 'CatalogueConfiguration'
        }.ConditionalVisibilityHelperWidget.modalInfo.imageUrl`,
      )}
    />
  );
};
const ConditionalVisibilityHelperWidgetMemo = memo(ConditionalVisibilityHelperWidget, equal);

const resetForm = (formik, initialValues) => {
  formik.resetForm({
    values: initialValues,
  });
};

/**
 * Displays a modal to inform about a company type conflict and provides options to resolve it.
 * @param {object} formik - The Formik object.
 * @param {array} relatedItems - An array of related items.
 * @param {object} initialValues - The initial values of the form.
 * @param {(key: string) => string} t - The translation function.
 * @param {object} newVisibility - The new values of the form.
 */
const informCompanyTypeConflictModal = (
  formik,
  relatedItems,
  initialValues,
  t,
  triggerUpdateCateogryItem,
  newVisibility,
) => {
  const formattedRelatedItems = (
    <ul>
      {relatedItems.map((item) => (
        <li>{item.name}</li>
      ))}
    </ul>
  );

  const formattedContetnt = (
    <>
      <p>{t('common.ConditionalVisibility.ConflictModal.content.intro')}</p>
      {formattedRelatedItems}
      <p>{t('common.ConditionalVisibility.ConflictModal.content.callForAction')}</p>
    </>
  );

  confirmModal({
    title: t('common.ConditionalVisibility.ConflictModal.title'),
    content: formattedContetnt,
    okText: t('common.ConditionalVisibility.ConflictModal.okText'),
    onOk: () => triggerUpdateCateogryItem(newVisibility),
    onCancel: () => resetForm(formik, initialValues),
    width: 800,
  });
};

/**
 * Shows the correct conflict modal based on the company type conflict
 * @param {object} inputParameters - The input parameters
 * @param {string} inputParameters.companyTypeConflict - The company type conflict
 * @param {object} inputParameters.formik - The formik object
 * @param {object[]} inputParameters.itemsReferencedByItem - The items referenced by the item
 * @param {object[]} inputParameters.itemsReferencingItem - The items referencing the item
 * @param {object} inputParameters.initialValues - The initial values of the form
 * @param {(key: string) => string} inputParameters.t - The translation function
 * @param {(key: string) => string} inputParameters.triggerUpdateCateogryItem - The function to trigger the update of the category item
 * @param {object} newVisibility - The new values of the form.
 */
const showConflictModal = ({
  companyTypeConflict,
  formik,
  itemsReferencedByItem,
  itemsReferencingItem,
  initialValues,
  t,
  triggerUpdateCateogryItem,
  newVisibility,
}) => {
  switch (companyTypeConflict) {
    case COMPANY_TYPE_CONFLICTS.REFERENCING_AND_REFERENCED_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        [...itemsReferencedByItem, ...itemsReferencingItem],
        initialValues,
        t,
        triggerUpdateCateogryItem,
        newVisibility,
      );
      break;
    case COMPANY_TYPE_CONFLICTS.REFERENCING_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        itemsReferencingItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
        newVisibility,
      );
      break;
    case COMPANY_TYPE_CONFLICTS.REFERENCED_ITEMS_CONFLICT:
      informCompanyTypeConflictModal(
        formik,
        itemsReferencedByItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
        newVisibility,
      );
      break;
    default:
  }
};

/**
 * The ConditionalVisibilityTab where users can set the company types for an item or category affecting the visibility in a shoppingcart
 * @param {object} param - The props passed to the component.
 * @param {string} param.parentId - The ID of the parent (either an item or category).
 * @param {string} [param.parentType='category'] - The type of the parent (either 'category' or 'categoryItem'). Default is 'category'.
 * @param {object} param.visibility - The visibility settings.
 * @param {React.Ref} param.formikRef - A reference to the Formik form used in the component.
 * @returns {React.Component} The ConditionalVisibilityTab component
 * @component
 */
const ConditionalVisibilityTab = ({ parentId, parentType = 'category', visibility, formikRef }) => {
  const { data: tenantCompanyData } = useCachedQuery(adminTenantCompanyQuery);
  const companyData = useMemo(() => grabFirstGQLDataResult(tenantCompanyData), [tenantCompanyData]);
  const companyTypes = useCompanyTypes();
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      ...visibility,
      annualReport: visibility?.annualReport.length === 1 ? visibility?.annualReport[0] : 'both',
    }),
    [visibility],
  );
  const { isLibrary } = useLibraryContext();

  /**
   * Handles the form submission, possibly displaying a modal to inform about a company type conflict.
   * @param {Object} values - The form values.
   * @param {Object} formik - The formik object.
   */
  // eslint-disable-next-line consistent-return
  const onSubmit = (values, formik) => {
    const newVisibility = {
      annualReport: values.annualReport === 'both' ? ['EUR', 'BIL'] : [values.annualReport],
      companyTypes: values.companyTypes,
      bookkeeping: values.bookkeeping,
    };
    if (parentType === 'category')
      updateCategory({
        _id: parentId,
        modifier: {
          visibility: newVisibility,
        },
      });
    if (parentType === 'categoryItem') {
      const { itemsReferencingItem, itemsReferencedByItem, companyTypeConflict } = doCompanyTypesConflict(
        newVisibility,
        isLibrary,
        parentId,
      );

      if (companyTypeConflict === COMPANY_TYPE_CONFLICTS.NONE) {
        triggerUpdateCateogryItem(newVisibility);
        return;
      }

      showConflictModal({
        companyTypeConflict,
        formik,
        itemsReferencedByItem,
        itemsReferencingItem,
        initialValues,
        t,
        triggerUpdateCateogryItem,
        newVisibility,
      });

      throw new Error('COMPANY_TYPE_CONFLICTS'); // ! This prevents the modal from navigating to the next item
    }
  };

  const triggerUpdateCateogryItem = (newVisibility) => {
    return updateCategoryItem({
      _id: parentId,
      visibility: newVisibility,
    });
  };

  return (
    <>
      <ConditionalVisibilityHelperWidgetMemo parentType={parentType} />
      <I18nFormik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} ref={formikRef}>
        <Form layout="vertical">
          <div className={classes.formContainer}>
            <AlertFromFormik />
            <CompanyTypeTransfer parentType={parentType} companyTypes={companyTypes} />
            <AnnualReportInput companyData={companyData} />
            <BookkeepingInput />
          </div>
          <SaveChangesButton initialValues={initialValues} />
        </Form>
      </I18nFormik>
    </>
  );
};
export default memo(ConditionalVisibilityTab, equal);
