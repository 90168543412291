import { gql } from '@apollo/client';

const adminDefaultTemplatesQuery = gql`
  query adminDefaultTemplatesQuery {
    getDigitalSignatureDefaultTemplates {
      noticeOfCancellationTemplate
      sepaMandateTemplateBase
      sepaMandateTemplateCompany
      startClientNotificationTemplate
      startTenantNotificationTemplate
      passwordEmailTemplate
      revokeEmailTemplate
      remindEmailTemplate
      signedBySigneeNotificationTemplate
      openedBySigneeNotificationTemplate
      successfulFinishedNotificationTemplate
    }
  }
`;

export default adminDefaultTemplatesQuery;
