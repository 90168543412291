import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './ConfirmModal.less';

const { confirm } = Modal;

/**
 * Confirm modal with custom properties
 * @param {object} props - Properties of the modal
 * @returns {?} reference to the modal
 */
const confirmModal = ({
  width,
  content,
  icon = <ExclamationCircleOutlined />,
  okText,
  cancelText,
  okType,
  onCancel, // cancel button
  onClose, // X icon or grey background click
  onOk, // ok button
  title = 'Do you Want to ...?',
  disableCloseButton,
  disableOkButton = false,
  maskClosable = false, // grey background clickable
  closable,
  className = '',
  cancelButtonProps = {},
  zIndex,
}) =>
  confirm({
    className,
    width,
    content,
    icon,
    okText,
    okType,
    cancelText,
    onCancel,
    onOk,
    onClose: onClose || onCancel,
    title,
    maskClosable,
    closable,
    cancelButtonProps: { ...cancelButtonProps, style: { display: disableCloseButton ? 'none' : 'block' } },
    okButtonProps: { disabled: disableOkButton },
    zIndex,
    // onClose: () => { console.log('onClose') },
    // onCancel: () => { console.log('onCancel') },
    // onOk: () => { console.log('onOk') },
  });

export default confirmModal;
