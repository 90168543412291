import { Transfer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import { renderCompanyType } from 'utils/helpers';
import classes from './CompanyTypeTransfer.module.less';

/**
 * Displays a transfer list for selecting company types.
 * @param {object} props - The component props.
 * @param {string} props.parentType - The type of the parent element ('category' or 'categoryItem').
 * @param {array} props.companyTypes - The list of company types to display in the transfer component.
 * @returns {JSX.Element} The rendered CompanyTypeTransfer component.
 * @component
 */
const CompanyTypeTransfer = ({ parentType, companyTypes }) => {
  const { t } = useTranslation();
  const { value: visibleForms, onChange: onFormItemChange } = useFormikField('companyTypes');
  const notSelectedKeys = companyTypes.map((item) => item.key).filter((key) => !visibleForms?.includes(key));

  const onChange = (nextTargetKeys) => {
    const newVisibleForms = companyTypes.map((item) => item.key).filter((key) => !nextTargetKeys.includes(key));
    onFormItemChange(newVisibleForms);
  };

  return (
    <div>
      <h4>
        {t(
          `admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.transfer.label.${parentType}`,
        )}
      </h4>
      <Transfer
        className={classes.styledTransfer}
        onChange={onChange}
        dataSource={companyTypes}
        render={renderCompanyType}
        targetKeys={notSelectedKeys}
        titles={[
          t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.transfer.rightTitle',
          ),
          t(
            'admin.CatalogueConfiguration.ConfigureCategoryProperties.properties.conditionalVisibility.transfer.leftTitle',
          ),
        ]}
      />
    </div>
  );
};

export default CompanyTypeTransfer;
