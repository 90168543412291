import ButtonSupportOnClickWhenDisabled from 'components/common/ButtonSupportOnClickWhenDisabled';
import { useCategoriesContext } from 'components/user/shoppingCart/context';
import { formikToResponse } from 'components/user/shoppingCart/utils';
import { useFormikContext } from 'formik';
import { filter, find, isEmpty, map, uniq, assign } from 'lodash';
import { Fragment, memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'utils/toast';
import { useCreateRefCB } from 'memo';
import { Card } from 'antd';
import { FaFilePdf, FaSave } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import settings from 'config/settings';
import s from './Buttons.module.less';

const isSelectedItemInCart = ({ cart }) =>
  find(cart, (cartCategory) => cartCategory.items?.length || cartCategory.bookmarkedItems?.length);
export const useButtonState = () => {
  const { values, errors, validateForm } = useFormikContext();
  const categories = useCategoriesContext();
  const { cart } = formikToResponse({ categories, values });
  const isSelectedItem = isSelectedItemInCart({ cart });
  const [isValidating, setIsValidating] = useState(false);
  const isValidatingRef = useRef();
  const isRecipients = values.contacts.filter((contact) => contact?.email).length;
  const disabled = isValidating || !isSelectedItem || !values.companyId || Object.values(errors).length;
  const disabledSendEmail = !isRecipients;
  const onClickRef = useCreateRefCB();
  const { t } = useTranslation();
  const isError = useMemo(() => Object.keys(errors).length, [errors]);
  const errorMessages = useMemo(
    () => ({
      all: (() => {
        if (!isSelectedItem) return t('user.ShoppingCart.Buttons.errors.noItemsSelectedError');
        if (!values.companyId) return t('user.ShoppingCart.Buttons.errors.noCompanyError');
        if (!values.contacts?.length) return t('user.ShoppingCart.Buttons.errors.noContactsError');
        if (isError) return t('user.ShoppingCart.Buttons.errors.formHasErrors');
        return null;
      })(),
      email: (() => {
        if (!isRecipients) return t('user.ShoppingCart.Buttons.errors.noRecipientsWithEmail');
        return null;
      })(),
    }),
    [isError, isRecipients, isSelectedItem, t, values.companyId, values.contacts?.length],
  );
  onClickRef.current =
    ({ isSendEmail } = {}) =>
    (submit) =>
    async (e) => {
      if (isValidatingRef.current) return;
      isValidatingRef.current = true;
      setIsValidating(true);
      // do task after end of render (after setState)
      const finish = () => {
        isValidatingRef.current = false;
        setIsValidating(false);
      };

      window.setTimeout(async () => {
        const error = errorMessages.all || (isSendEmail && errorMessages.email);
        if (error) return finish(toast.error(error));

        const formErrors = await validateForm();
        const allErrors = assign({}, formErrors, errors);
        submit(e);
        if (!isEmpty(allErrors)) {
          toast.error(t('user.ShoppingCart.Buttons.errors.formHasErrors'));
          try {
            const errorFieldNames = Object.keys(allErrors);
            const orderedFormElements = [...(document.querySelectorAll(`[fieldname]`) || [])];
            const elementsWithErrors = filter(orderedFormElements, (el) =>
              errorFieldNames.includes(el.getAttribute('fieldname')),
            );
            if (!elementsWithErrors.length) console.warn('Not found elements with error');
            const tabs = map(elementsWithErrors, (elementWithError) =>
              elementWithError
                .closest('.ant-collapse-item')
                ?.querySelector('.ant-collapse-header[aria-expanded="false"]'),
            );
            const uniqTabs = uniq(tabs.filter(Boolean));
            const currentOffset = e.target.offsetTop - window.scrollY;
            uniqTabs.forEach((tab) => tab.click());
            window.scrollTo({ top: e.target.offsetTop - currentOffset });
            const firstElementWithError = elementsWithErrors[0];
            window.setTimeout(
              () => firstElementWithError?.scrollIntoView({ block: 'center', behavior: 'smooth' }),
              uniqTabs.length ? 500 : 100,
            );
          } catch (err) {
            console.error(err);
          }
        }
        return finish();
      }, 0);
    };
  return { onClickRef, disabled, disabledSendEmail, errors: errorMessages };
};
export const SubmitButton = ({ isSubmitting, currentSubmitTypeRef, type, disabled, onClick, buttonProps, label }) => {
  return (
    <ButtonSupportOnClickWhenDisabled
      onClick={onClick}
      loading={isSubmitting && currentSubmitTypeRef.current.type === type}
      disabled={disabled || (isSubmitting && currentSubmitTypeRef.current.type !== type)}
      type="default"
      size="large"
      className={s.btn}
      buttonProps={buttonProps}
    >
      {label}
    </ButtonSupportOnClickWhenDisabled>
  );
};
const FragmentNoProps = ({ children }) => children;
const ButtonsComponent = ({
  currentSubmitTypeRef,
  disabled,
  disabledSendEmail,
  errors,
  handleSubmit,
  isSubmitting,
  onClickRef,
  t,
  schema = [
    {
      buttonProps: { icon: <FaSave /> },
      error: errors?.all,
      label: t('user.ShoppingCart.Buttons.store'),
      type: 'save',
    },
    settings.hideEmailButtons
      ? null
      : {
          buttonProps: {
            type: 'primary',
            icon: <GrMail size={19} />,
          },
          disabled: disabledSendEmail,
          error: errors?.email || errors?.all,
          label: t('user.ShoppingCart.Buttons.email'),
          type: 'email',
        },
    {
      buttonProps: { icon: <FaFilePdf />, type: 'primary' },
      error: errors?.all,
      label: t('user.ShoppingCart.Buttons.print'),
      type: 'print',
    },
  ].filter(Boolean),
  onlyButtons,
}) => {
  const memoRefs = [useCreateRefCB(), useCreateRefCB(), useCreateRefCB(), useCreateRefCB()];
  const Container = onlyButtons ? FragmentNoProps : Card;
  return (
    <Container>
      {schema.map(({ disabled: disabledButton, label, buttonProps, type, submitProps = {} }, index) => {
        const isSendEmail = type === 'email';
        return (
          <Fragment key={label}>
            {index !== 0 ? ' ' : null}
            <SubmitButton
              isSubmitting={isSubmitting}
              currentSubmitTypeRef={currentSubmitTypeRef}
              type={type}
              disabled={disabled || disabledButton}
              onClick={memoRefs[index](
                onClickRef.current({
                  isSendEmail,
                })((e) => {
                  if (isSubmitting) return;
                  // eslint-disable-next-line no-param-reassign
                  Object.assign(currentSubmitTypeRef.current, { type, ...submitProps });
                  handleSubmit(e);
                }),
              )}
              buttonProps={buttonProps}
              label={label}
            />
          </Fragment>
        );
      })}
    </Container>
  );
};
export const MemoButtonsComponent = memo(ButtonsComponent);

export const Buttons = ({ currentSubmitTypeRef, isSubmitting, buttonState, schema, onlyButtons }) => {
  const { handleSubmit } = useFormikContext();
  const { t } = useTranslation();
  const { onClickRef, disabled, disabledSendEmail, errors } = buttonState;
  return (
    <MemoButtonsComponent
      onClickRef={onClickRef}
      t={t}
      isSubmitting={isSubmitting}
      currentSubmitTypeRef={currentSubmitTypeRef}
      handleSubmit={handleSubmit}
      disabled={disabled}
      errors={errors}
      disabledSendEmail={disabledSendEmail}
      schema={schema}
      onlyButtons={onlyButtons}
    />
  );
};

export default Buttons;
