import i18n from 'i18n';
import * as Yup from 'yup';
import { PhoneInputValidationSchema } from 'components/common/PhoneInputFormik';

const textSchemaRequire = () => Yup.string().required().min(1).nullable();

/**
 * Schema for selecting existing client form
 * @returns {Yup.ObjectSchema<object>}
 */
export const CompanySelectSchema = () =>
  Yup.object().shape({
    company: textSchemaRequire().label(i18n.t('user.ShoppingCart.ContactData.company.inputs.company.label')),
  });

/**
 * Schema for creating or editing client form
 * @returns {Yup.ObjectSchema<object>}
 */
export const ClientSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(i18n.t('ClientsListPage.addClientModal.form.name.required')),
    type: Yup.string().nullable().required(i18n.t('ClientsListPage.addClientModal.form.type.required')),
    legalForm: Yup.object().when('type', {
      is: (type) => type === 'company',
      then: Yup.object().shape({
        companyType: Yup.string()
          .nullable()
          .required(i18n.t('ClientsListPage.addClientModal.form.companyType.required')),
        annualReport: Yup.string()
          .nullable()
          .required(i18n.t('ClientsListPage.addClientModal.form.annualReport.required')),
        bookkeeping: Yup.string()
          .nullable()
          .required(i18n.t('ClientsListPage.addClientModal.form.bookkeeping.required')),
      }),
      otherwise: Yup.object().shape({
        companyType: Yup.string().nullable(),
        annualReport: Yup.string().nullable(),
        bookkeeping: Yup.string().nullable(),
      }),
    }),
  });

export const contactFormSchema = () => ({
  salutation: Yup.string().nullable().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.salutation.label')),
  firstName: textSchemaRequire().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.firstName.label')),
  lastName: textSchemaRequire().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.lastName.label')),
  email: Yup.string().email().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.email.label')),
  phoneNumber: PhoneInputValidationSchema().label(
    i18n.t('user.ShoppingCart.ContactData.contact.inputs.phoneNumber.label'),
  ),
  phoneNumberAlternative: PhoneInputValidationSchema().label(
    i18n.t('user.ShoppingCart.ContactData.contact.inputs.phoneNumberAlternative.label'),
  ),
});

export const contactFormTabSchema = ({ values }) => {
  return Yup.object().shape({
    ...(values?.tab === '1' && {
      contact: textSchemaRequire().label(i18n.t('user.ShoppingCart.ContactData.contact.inputs.contact.label')),
    }),
    ...(values?.tab === '2' && contactFormSchema()),
  });
};
