import { useMemo } from 'react';
import useCompanyTypes from 'hooks/user/useCompanyTypes';
import { CompanyTypeUtility } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { useTranslation } from 'react-i18next';
import { Select } from 'formik-antd';
import { renderCompanyType } from 'utils/helpers';
import FormItem from 'components/common/FormComponents/Formik/FormItem';

/**
 * Renders a dropdown (select) component for choosing a company type.
 * @component
 * @returns {JSX.Element} The rendered select component wrapped in a form item.
 */
const ClientCompanyTypeSelect = () => {
  const { t } = useTranslation();
  const companyTypes = useCompanyTypes({ filterOut: ['Privatperson'] });
  const companyTypesUtilityInstance = useMemo(() => new CompanyTypeUtility(), []);

  return (
    <FormItem label={t('ClientsListPage.addClientModal.form.companyType.label')} name="legalForm.companyType">
      <Select
        name="legalForm.companyType"
        placeholder={t('ClientsListPage.addClientModal.form.companyType.placeholder')}
        notFoundContent={t('ClientsListPage.addClientModal.form.companyType.notFound')}
        showSearch
        options={companyTypes.map((type) => ({
          value: type.key,
          label: renderCompanyType(type),
        }))}
        filterOption={(input, option) => {
          const searchValue = input?.toLowerCase() || '';
          const partsOfSearchValue = searchValue.split(' ');
          const { country, abbreviation } = companyTypesUtilityInstance.getObjectByKey(option.value);
          const partsOfOption = [
            String(option.value).toLowerCase(),
            country?.toLowerCase(),
            abbreviation?.toLowerCase(),
          ].filter(Boolean);
          return partsOfSearchValue.every((part) => partsOfOption.some((optionPart) => optionPart.includes(part)));
        }}
      />
    </FormItem>
  );
};

export default ClientCompanyTypeSelect;
