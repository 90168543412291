import { find } from 'lodash';
import { useFormikContext } from 'formik';
import { useCategoriesContext } from 'components/user/shoppingCart/context';

/**
 * Custom hook to check if there are selected items in the form based on the current categories and form values.
 * @returns {boolean} Returns `true` if there are selected items, otherwise `false`.
 * @hook
 */
const useHasSelectedItems = () => {
  const categories = useCategoriesContext();
  const { values } = useFormikContext();
  if (!categories || !values) return false;
  return !!find(categories, (category) => category?.items?.find(({ _id }) => values[_id]));
};

export default useHasSelectedItems;
