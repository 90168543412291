import DocumentTemplateSelect from 'components/common/DocumentTemplateSelect';

/**
 * A React function component that renders a DocumentTemplateSelect component for SEPA documents.
 *
 * @component
 * @param {Object} props - The properties passed to this component.
 * @param {function} props.t - The translation function used for internationalization.
 * @param {'base' | 'company'} props.type - The type of document template to be selected.
 * @returns {ReactElement} - A DocumentTemplateSelect component tailored for SEPA document templates.
 * @description
 * `SepaDocumentTemplateSelect` is a React function component that acts as a wrapper around the `DocumentTemplateSelect` component.
 * It is specifically configured to work with SEPA document templates. The component accepts a translation function as a prop
 * and uses it to provide localized strings for the label, placeholder, and tooltip of the `DocumentTemplateSelect` component.
 *
 * @example
 * <SepaDocumentTemplateSelect t={yourTranslationFunction} />
 */
export const SepaDocumentTemplateSelect = ({ t, type = 'base' }) => {
  const sepaType = type === 'base' ? 'sepaDocumentTemplateBase' : 'sepaDocumentTemplateCompany';

  return (
    <DocumentTemplateSelect
      fieldName={sepaType}
      label={t(`admin.Setting.DigitalSignaturePreferences.sepa.documentTemplateSelect.${type}.label`)}
      placeholder={t(`admin.Setting.DigitalSignaturePreferences.sepa.documentTemplateSelect.${type}.placeholder`)}
      tooltip={t(`admin.Setting.DigitalSignaturePreferences.sepa.documentTemplateSelect.${type}.tooltip`)}
    />
  );
};
