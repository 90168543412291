import RadioButtonsWithLabel from 'components/common/FormComponents/Formik/RadioButtonsWithLabel';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikField } from 'hooks/common/useFormikField';
import { CompanyTypeUtility } from '@JavaScriptSuperstars/kanzleipilot-shared';
import { getAnnualReportLabel } from 'utils/helpers';

/**
 * Generates options for the annual report field based on the company type. (Needs formik context)
 * @returns {Array<{label: string, value: string}> | null} Annual report options, or null if no company type is selected.
 */
const useAnnualReportOptions = () => {
  const { t } = useTranslation();
  const { value: companyTypeKey } = useFormikField('legalForm.companyType');
  const companyTypeUtilityInstance = useMemo(() => new CompanyTypeUtility(), []);

  if (!companyTypeKey) return null;

  const companyType = companyTypeUtilityInstance.getObjectByKey(companyTypeKey);

  if (!companyType) return null;

  const { country } = companyType;
  const annualReportValues = (companyType.annualReport?.length > 0 ? companyType.annualReport : ['BIL', 'EUR']).sort();

  return annualReportValues.map((annualReport) => ({
    label: t(`ClientsListPage.addClientModal.form.annualReport.options.${getAnnualReportLabel(annualReport, country)}`),
    value: annualReport,
  }));
};

/**
 * Renders a radio button group for selecting an annual report option.
 * Automatically selects the first option if there is only one.
 * @component
 * @returns {JSX.Element | null} The rendered radio button group or null if options are not available.
 */
const ClientAnnualReportSelect = () => {
  const { t } = useTranslation();
  const annualReportValues = useAnnualReportOptions();
  const { value, onChange } = useFormikField('legalForm.annualReport');

  useEffect(() => {
    if (annualReportValues?.length === 1 && value !== annualReportValues[0].value)
      onChange(annualReportValues[0].value);
  }, [annualReportValues, onChange, value]);

  if (!annualReportValues) return null;

  return (
    <RadioButtonsWithLabel
      name="legalForm.annualReport"
      label={t('ClientsListPage.addClientModal.form.annualReport.label')}
      options={annualReportValues}
      groupProps={{ defaultValue: annualReportValues.length === 1 ? annualReportValues[0].value : null }}
    />
  );
};

export default ClientAnnualReportSelect;
